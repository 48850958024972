import {
    ContactUsBodyContent,
    ContactUsBodyHero,
  ContactUsHero,
  ContactUsHeroContent,
  ContactUsIcon,
  ContactUsMainDiv,
} from "../../styles/contactusstyles/contactUsStyles";
import { SmallGradientHero, TextHeader } from "../../styles/reusable";
import Footer from "../reuseable/footer";
import AppHeader from "../reuseable/header";
import { MdOutlineSupportAgent } from "react-icons/md";

import { IoMailUnreadOutline } from "react-icons/io5";
import { TbUserQuestion } from "react-icons/tb";
import { MdOutlineVideoCameraFront } from "react-icons/md";
import { MdOutlineQuestionAnswer } from "react-icons/md";


const ContactUsComponents = () => {
  return (
    <ContactUsMainDiv>
      <ContactUsHero>
        <AppHeader
          hideContact={false}
          logo={"/images/sixthBlue.png"}
          top="0.5rem"
          width="10rem"
          mobileWidth="8rem"
          invert={true}
        />
        <ContactUsHeroContent>
          <TextHeader> Contact us</TextHeader>
          <p>Please select an option below to get in touch</p>
        </ContactUsHeroContent>
      </ContactUsHero>
      <ContactUsBodyHero>
       
        {
            data.map((item, index) =>(
                <SmallGradientHero src={item.img} min_width="500px" width="40%" height="30rem" >
                    <ContactUsBodyContent>
                    <ContactUsIcon>
                        {item.icon}
                    </ContactUsIcon>
                    <TextHeader> {item.name}</TextHeader>
                    <p> {item.describe}</p>
                    <div>
                    <IoMailUnreadOutline  size={50} />  {
                        item.links.map((mails,index) =>(
                            <a href={mails.url}>   
                            {mails.email} </a>   
                        ))
                    }
                     
                    </div>
                    
                   

                    </ContactUsBodyContent>
               
    
    
            </SmallGradientHero>
            ))
        }
      </ContactUsBodyHero>
      <Footer></Footer>
    </ContactUsMainDiv>
  );
};

const data =[
    {
        icon:<MdOutlineSupportAgent size={46} />,
        name:"Support",
        img:"images/3D Man chatting remotely with female colleague illustration.jpg",

        describe:"For customer support questions",
        links:[{email: "supprt@trysixth.com",url:"mailto:ope@trysixth.com"}],
    },

    {
        icon:<TbUserQuestion size={46} />,
        img:"images/3D woman talking with chatbot Illustration.jpg",

        name:"General Inquiries",
        describe:"For general queries, including partnership opportunities",
        links:[{email: "info@trysixth.com",url:"mailto:ope@trysixth.com"}],
    },
    
    {
        icon:<MdOutlineQuestionAnswer size={46} />,
        name:"Sales",
        img:"images/3D young man in headphone sitting with laptop and waving Illustration.jpg",

        describe:"Interested in learning more about Forter?",
        links:[{email: "sales@trysixth.com",url:"mailto:ope@trysixth.com"}],
    },
    {
        icon:<MdOutlineVideoCameraFront size={46} />,
        img:"images/3D youg people discussing the project illustration.jpg",

        name:"Media and press",
        describe:"For company information and media resources",
        links:[{email: "media@trysixth.com",url:"mailto:ope@trysixth.com"}],
    },

]

export default ContactUsComponents;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { FaDiscord } from "react-icons/fa";

import {
  CopyRightText,
  FooterAbout,
  FooterFlex,
  FooterMain,
  FooterNavigations,
  FooterSocialWrap,
  RoundedSheet,
} from "../../styles/footer";
import * as Icon from "react-feather";
import { useNavigate } from "react-router-dom";
import { LogoImage } from "../../styles/headerStyle";
import { Link } from "react-router-dom";

interface PropsArgs {
  scrollToElement?: any;
  scrollToAbout?: any;
}

const Footer = ({ scrollToElement, scrollToAbout }: PropsArgs) => {
  const navigate = useNavigate();
  const date = new Date();
  let year = date.getFullYear();


  const openAboutUs =()=>{
    navigate("/company")
  }

  const handleToContact = () => {
    window.open("mailto:ope@trysixth.com", "_blank");
  };

  const quickLinks = [
    {
      name: "About",
      url: "",
      action: openAboutUs,
    },
    {
      name: "Contacts",
      url: "mailto:ope@trysixth.com",
      action: handleToContact,
    },
    {
      name: "Help",
      url: "",
      action: handleToContact
    },
  ];

  const lastRow = [
    {
      name: " Tools",
      url: "https://docs.trysixth.com",
      action: "",
    },
    //{
    //  name: "Careers",
    //  url: "",
    //  action: "",
    //},
  ];

  return (
    <>
      <FooterMain>
        <FooterFlex>
          <FooterAbout>
            <LogoImage
              width={"10rem"}
              src={"/images/sixth_light_blue.png"}
              alt="sixth icon"
              top="0.5rem"
              mobileWidth="8rem"
            />
          </FooterAbout>
          <FooterNavigations>
            <div>
              {quickLinks.map((item, index) => (
                <p
                  
                  key={index}
                  onClick={() => {
                    item.action();
                  }}
                >
                  {item.name}
                </p>
              ))}
            </div>
            <div>
              {usefulLinks.map((item, index) => (
                <Link to={item.url}>
                  <p
                   
                    key={index}
                    
                  >
                    {item.name}
                  </p>
                </Link>
              ))}
            </div>
            <div>
              {lastRow.map((item, index) => (
                <p  key={index}>
                  {item.name}
                </p>
              ))}
            </div>
          </FooterNavigations>
        </FooterFlex>
        <FooterFlex
          align="center"
          style={{
            margin: "4rem 0 0 0",
          }}
        >
          <CopyRightText >© {year}, Sixth | All Rights Reserved</CopyRightText>
          <FooterSocialWrap>
            <div>
              <a
                href="https://twitter.com/sixth_hq"
                target="_blank"
                rel="noreferrer"
              >
                <Icon.Twitter size={15} strokeWidth={2} />
              </a>
            </div>
            <div>
              <a
                href="https://www.instagram.com/trysixth/"
                target="_blank"
                rel="noreferrer"
              >
                <Icon.Instagram size={15} strokeWidth={2} />
              </a>
            </div>
            <div>
              <a
                href="https://discord.gg/CyF9NrsaGg"
                target="_blank"
                rel="noreferrer"
              >
                <FaDiscord size={15} strokeWidth={2} />
              </a>
            </div>
            <div>
              <a
                href="https://www.linkedin.com/mwlite/company/trysixth"
                target="_blank"
                rel="noreferrer"
              >
                <Icon.Linkedin size={15} strokeWidth={2} />
              </a>
            </div>
          </FooterSocialWrap>
        </FooterFlex>

        <RoundedSheet></RoundedSheet>
      </FooterMain>
    </>
  );
};

export default Footer;

const usefulLinks = [
  {
    name: "Developer",
    url: "https://doc.trysixth.com",
    action: "",
  },
  {
    name: "Terms and Condition",
    url: "/",
    action: "",
  },
  {
    name: "Privacy Policy",
    url: "/privacy-Security",
    action: "",
  },
  {
    name: "Blogs",
    url: "https://blog.trysixth.com",
    action: "",
  },
];

import styled from "styled-components";

export const ChatWithUsMainDiv = styled.div``;

export const ChatWithUsMain = styled.div`
  width: 100%;
  min-height: 100vh;
  background: #1d49ab;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    margin-left: 8rem;
  }
  @media (max-width: 450px) {
 
    
  }
`;

export const ChatWithUsMainHeader = styled.div`
  h1 {
    color: white;
    text-align: center;
    font-size: 38px;
    font-weight: 1000;
    margin-top: 7%;
    margin-left: 0px;
  }
`;

export const ChatWithUsMainBody = styled.div`
  width: 40vw;
  min-height: 80vh;
  background: white;
  margin-top: 2rem;
  margin-bottom: 4rem;
  border-radius: 8px;
  
  display: flex;
  flex-direction: column;
  h1 {

    color:var(--text-light-blue);
    text-align: center;
    font-size: 1.2rem;
    font-weight: 1000;
    margin-top: 7%;
    margin-left: 0px;
    line-height: 30px;
  }

  @media (max-width: 1200px) {
    width: 70vw;
    
  }

  
  @media (max-width: 500px) {
    width: 90vw;
    
  }

  
`;

export const ChatWithUsMainField = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 50px;
  padding: 4%;

  p {
    font-size: 1rem;
    text-align: center;
  }
  
`;


const primary = "#11998e";
const secondary = "#38ef7d";
const gray = "#9b9b9b";


export const ChatWithUsFormGroupField = styled.div`
  position: relative;
  padding: 15px 0 0;
  margin-top: 10px;
  width: 40%;
  
  @media (max-width: 450px) {
     width: 80%;
    
 
    
  }
`;


export const ChatWithUsFormLabel = styled.label`
position: absolute;
top: 0;
display: block;
transition: 0.2s;
font-size: 1rem;
color: ${gray};

@media (max-width:500px){
  font-size: .7rem;

}
`;

export const ChatWithUsFormInput = styled.input`
font-family: inherit;
width: 100%;
border: 0;
border-bottom: 2px solid ${gray};
outline: 0;
font-size: 1.3rem;
padding: 7px 0;
background: transparent;
transition: border-color 0.2s;

&::placeholder {
  color: transparent;
}

&:placeholder-shown ~ ${ChatWithUsFormLabel} {
  font-size: 1.3rem;
  cursor: text;
  top: 20px;
}


&:focus {
  ~ ${ChatWithUsFormLabel} {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 1rem;
    color: ${primary};
    font-weight: 700;
  }
  padding-bottom: 6px;
  font-weight: 700;
  border-width: 3px;
  border-image: linear-gradient(to right, ${primary}, ${secondary});
  border-image-slice: 1;
}

@media (max-width:500px){
  font-size: 1rem;

  &:focus {
    ~ ${ChatWithUsFormLabel} {
      font-size: .7rem;
      font-weight: 600;
    }
   
  }
  
&:placeholder-shown ~ ${ChatWithUsFormLabel} {
  font-size: 1rem;

}

}

`;


import Pricing from "../components/pricing";
import Footer from "../components/reuseable/footer";



const PricingPage = () => {

  return (
    <>
      <div className="main-widget">
        <Pricing></Pricing>
       
        <Footer/>
      </div>
    </>
  );
};

export default PricingPage;

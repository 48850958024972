import styled from "styled-components";

export const HeroHeaderWrap = styled.div`
  min-height: 80vh;

  > div {
    //background: url("/images/gradientHero.png");
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
  }

  @media (max-width: 450px) {
    height: auto;
  }
`;

interface HeroMainProp {
  bg?: string;
  height?: string;
  align?: string;
}

export const HomeHeroDiv = styled.div<HeroMainProp>`
  position: relative;
  display: flex;
  align-items: ${(p) => p.align || "start"};
  justify-content: space-between;
  margin: 0rem 1.4rem;
  background: ${(p) => p.bg || "transparent"};
  padding: 2rem 4%;
  min-height: ${(p) => p.height || "auto"};

  @media (max-width: 1440px) {
  }

  @media (max-width: 850px) {
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 450px) {
    flex-direction: column;
    width: auto;

    padding: auto;
    margin: 0px;
  }
`;

interface ImgHeroProp {
  bottom?: string;
  left?: string;
  width?: string;
  widthAt1000px?: string;
}

export const ImgHero = styled.div<ImgHeroProp>`
min-width:50%;
position:relative;


.imgHero {
  position:absolute;
  left: ${(p) => p.left || "100vw"} ;
  width: ${(p) => p.width || "100%"} ;
  bottom: ${(p) => p.bottom || " -5rem"} ;



}



@media (max-width: 900px) {
  margin:1rem ;
  
  .imgHero{
  position:relative;
  width: ${(p) => p.widthAt1000px || "60%"} ;

  left: 0%;
  bottom: auto; 
}

  
 
}
@media (max-width: 500px) {
  width:100%;
  .imgHero{
      position:relative;
      width:  100%;
      bottom: auto;
      left:auto;
    }

    >h4{
      margin:0;
      padding:0;
    }
 
`;

export const TrustedSection = styled.div`
  margin: 3rem auto;
  align-items: center;
  text-align: center;
  > h1 {
    color: var(--text-blue);
  }

  > h3 {
    font-size: 24px;
    margin: 0 0 2rem 0;
    line-height: 24px;
    font-weight: 700;
  }

  > div {
    display: flex;
    align-items: Center;
    justify-content: center;
    gap: 34px;
    flex-wrap: Wrap;
    padding-top: 24px;
  }
`;
interface TrustedSectionImgProp {
  imgWidth?: string;
  imgHeight?: string;
  mobileHeight?: string;
  mobileE?: string;
}
export const TrustedSectionImg = styled.img<TrustedSectionImgProp>`
  width: ${(p) => p.imgWidth || "30rem"};
  height: ${(p) => p.imgHeight || "60px"};

  @media (max-width: 550px) {
    width: 40%;
    height: ${(p) => p.mobileHeight || "60px"};
  }
`;

import CustomerComponent from "../components/CustomerComps/CustomerComponent"

const CustomerPage = ()=>{

    return(
        <>
        <CustomerComponent></CustomerComponent>
        </>
    )

    
}

export default CustomerPage
import styled from "styled-components";

export const EventsMainDiv = styled.div``;

export const EventsHero = styled.div`
  min-height: 50vh;
  background: var(--sixthBlue);
`;

export const EventsHeroContent = styled.div`
  min-height: 50vh;
  background: var(--sixthBlue);
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-center: center;
  flex-direction: column;

  > h1 {
    color: white;
    font-size: 3.625rem;
    line-height: 1.125;
  }
  > p {
    margin: 0;
    color:white;
    font-size: 1.25rem;
    line-height: 1.375;
  }

  @media (max-width: 460px) {
    text-align: center;
    padding-top:3rem;
    > h1 {
      color: white;
      font-size: 2.625rem;
      line-height: 1.125;
    }
  }
`;

export const EventsDiv = styled.div`
  display: flex;
  justify-content: center;

  margin: 4rem;
  
  @media (max-width: 460px) {

    flex-direction: column;
  
  }
`;

interface EventsProps {
  src?: string;
}
export const Events = styled.div<EventsProps>`
  min-width: 55%;
  box-sizing: border-box;
  flex: 1;
  border-radius: 10px;
  position: relative;
  display: flex;
  min-height: 600px;
  overflow: hidden;
  background-image: url("${(p) => p.src || "0.7rem 1.5rem"}");
  background-size: cover;
  background-position: center;
  margin: 2% 0;
  > div {
    width: 100%;
    min-height: 30vh;

    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--sixth-gradient7);
  }
  @media (max-width: 460px) {
    min-width: 250px;
    min-height: 300px;
    height: 300px;
  }
`;

export const EventsCover = styled.div`
  flex: 1;
  border-radius: 10px;

  cursor: pointer;
  background: var(--sixth-gradient7);
  transition: all 1s;
  z-index: 2;
`;

export const EventsIcon = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 40px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--sixthBlue);
`;

export const EventsInfo = styled.div`
  border-radius: 10px;
  transform: translateX(-5%);
  max-width: 1000px;

  color: white;
  background-color: var(--text-blue);
  padding: 20px;
  box-sizing: border-box;
  text-align: left;
  padding-left: 2rem;
  > h2,
  h1 {
    color: white;
  }

  > p {
    color: white;
    font-size: 1.1rem;
    margin: 0;
    padding: 0;
  }
  @media (max-width: 460px) {
    text-align:left;
    padding: 20px;
    >h1{
      font-size:1.8rem;
      line-height:1.3;
      text-align:left;
    }
    > p {
      color: white;
      font-size: .9rem;
      text-align:left;
     
    }
    transform: translateX(0%);
   
  }
`;

export const EventsType = styled.div`
  width: fit-content;
  background: var(--sixthBlue);
  padding: 6px;
  border-radius: 30px;
  
  @media (max-width: 460px) {
    padding: 3px;
      font-size: .7rem;
     
  
   
   
`;

export const EventsFilter = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: start;

  padding: 1rem;
  box-shadow: 0 0.25rem 1rem rgba(0, 93, 232, 0.1);
`;

export const EventsContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 1rem;
  flex-direction: column;

  background: rgba(0, 93, 232, 0.1) l;
`;

export const EventsNews = styled.div`
  box-sizing: border-box;
  flex: 1;
  position: relative;
  display: flex;
  min-height: 600px;
  overflow: hidden;
  width: 90%;

  justify-content: center;
  min-height: 30vh;
  margin: 2% 0;

  cursor: pointer;

  &:hover {
    > div {
      &.news {
        background: white;
        box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
      }
    }
  }

  @media (max-width: 460px) {
    min-width: 250px;
    flex-direction: column;
    min-height: 300px;
  }
`;

export const EventsNewsHero = styled.div<EventsProps>`
  width: 40%;

  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  overflow: hidden;
  color: white;
  background: var(--sixth-gradient6);
  padding: 5rem;
  transition: all 0.6s;
  background-image: url("${(p) => p.src || "0.7rem 1.5rem"}");
  background-size: fill;
  background-position: center;
  &.news {
    background: transparent;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  p {
    color: black;
    font-size: 1rem;
    margin: 4px;
    padding: 0;
  }
  h1 {
    color: black;
    font-size: 2.3rem;
    line-height: 1.25;
    margin: 4px;
    padding: 0;
  }
  > section {
    width: 100%;
    padding: 1rem;
    flex-direction: column;
    display: flex;
    justify-content: end;
    gap: 1rem;
  }
  @media (max-width: 1060px) {
    min-width: 450px;
    
    padding: 1rem;
    
  }

  @media (max-width: 460px) {
    min-width: 250px;
    width:100%;
    height: 300px;
    padding: 0rem;
    &.news {
      background: white;
      box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;   
     }

    h1 {
    
      font-size: 1.3rem;
    
    }
  }
`;

import styled from "styled-components";

export const AboutUsMainDiv = styled.div``;

export const AboutUsHeadDiv = styled.div`
  min-height: 50vh;
  background: var(--sixthBlue);
`;

export const AboutUsHeadHeroDiv = styled.div`
  display: flex;
  padding: 1rem 2rem 1rem 12%;
  box-sizing: border-box;
  color: white;

  > div {
    flex: 1;
    margin: 0 1%;
    padding: 1rem 2rem 1rem 1%;
    display: flex;
    flex-direction: column;
    align-items: start;
    box-sizing: border-box;
    max-width: 42rem;
  }

  @media (max-width: 460px) {
    padding: 2rem 0.3rem;
    overflow: hidden;
  }
`;

export const AboutUsHeadHero = styled.div`
  display: flex;
  box-sizing: border-box;

  > h1 {
    color: white;
    font-size: 3.625rem;
    line-height: 1.125;
  }
  > p {
    margin: 0;
    font-size: 1.25rem;
    line-height: 1.375;
  }

  @media (max-width: 460px) {
    text-align: center;
    > h1 {
      color: white;
      font-size: 2.625rem;
      line-height: 1.125;
    }
  }
`;

export const AboutUsStoryDiv = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;

  justify-content: center;
`;

export const AboutUsStory = styled.div`
  padding: 5rem;
  background: var(--sixthBlue);
  margin: 3%;
  color: white;
  border-radius: 10px;
  text-align: center;
  width: 55%;
  box-sizing: border-box;

  max-width: 64rem;
  > h1 {
    color: white;
    font-size: 2.75rem;
    line-height: 1.125;
  }
  > h2 {
    font-size: 1.375rem;
    line-height: 1.375;
    color: inherit;
  }
  > p {
    margin: 0;
    font-size: 1.25rem;
    line-height: 1.375;
  }

  @media (max-width: 1150px) {
    padding: 2rem;
    width: 85%;
  }

  @media (max-width: 450px) {
    padding: 2rem 1rem;
    width: 95%;
  }
`;

export const AboutUsWorkWithUsDiv = styled.div`
  margin: 5rem 0;
  align-items: center;
  text-align: center;
  padding: 6rem 1rem;
  color: white;
  box-sizing: border-box;
  background: radial-gradient(
    100% 100% at 50% 50%,
    var(--sixthBlue) 0%,
    var(--text-blue) 100%
  );
  > h1 {
    color: white;
    font-size: 3.75rem;
    line-height: 1.125;
  }
  > p {
    margin: 0;
    font-size: 1.25rem;
    line-height: 1.375;
    margin: 20px;
  }
`;

export const AboutUsLocation = styled.div`
  padding: 40px 2rem;
  margin: 5rem 0;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  > h1 {
    font-size: 3.75rem;
    line-height: 1.125;
  }
  > p {
    margin: 0;
    font-size: 1.25rem;
    line-height: 1.375;
    margin: 20px;
  }
`;

export const LocationDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4rem;
`;

interface LocationProps {
  src?: string;
}
export const Location = styled.div<LocationProps>`
  width: 15%;
  min-width: 300px;
  box-sizing: border-box;
  height: 400px;
  background: transparent;
  border-radius: 10px;
  position: relative;
  box-sizing: border-box;

  background-image: url("${(p) => p.src || "0.7rem 1.5rem"}");
  background-size: cover;
  background-position: center;
  @media (max-width: 460px) {
    min-width: 250px;
    height: 300px;
  }
`;

export const LocationCover = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  cursor: pointer;
  flex-direction: column;
  justify-content: end;
  background: var(--sixth-gradient7);
  transition: all 1s;
  z-index: 2;
`;

export const LocationInfo = styled.div`
  width: fit-content;
  border-radius: 10px;
  position: absolute;
  left: 100px;
  top: 20%;
  min-width: 260px;
  color: white;
  background-color: var(--text-blue);
  padding: 5px 5px;
  box-sizing: border-box;
  text-align: left;
  padding-left: 2rem;
  > h2,
  h1 {
    color: white;
  }

  > p {
    color: white;
    font-size: 1.1rem;
    margin: 0;
    padding: 0;
  }
  @media (max-width: 460px) {
    left: 40px;
    top: 13%;
  }
`;

export const TeamMemberContainer = styled.div`
  display: flex;
  align-item: center;
  justify-content: center;
`;
export const TeamMemberDiv = styled.div`
  display: grid;
  width: 64rem;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 10px;

  padding: 10px;
   div {
    &.role {
      color:white;
      text-align:left;
      padding-left:2rem;
      padding-top:.4rem;
      font-size: 1.2rem;
      text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;

    }
  }

  @media (max-width: 950px) {
    display: grid;
    padding: 1rem;
    grid-template-columns: repeat(3, minmax(0, 1fr));

    width: 100%;
    padding: 1rem;
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media (max-width: 460px) {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

export const TeamMember = styled.div<LocationProps>`
  background: transparent;
  border-radius: 10px;
  position: relative;
  height: 300px;
  background:var(--text-blue) ;
  background-image: url("${(p) => p.src || "0.7rem 1.5rem"}");
  background-size: cover;
  background-position: center;
  overflow: hidden;
  &.first {
    margin-top: 20px;
  }
  &.second {
    margin-top: 50px;
  }
  &.third {
    margin-top: 30px;
  }
  &.fourth {
  }

  

  @media (max-width: 960px) {
    width:250px;
  }
  
  @media (max-width: 600px) {
    width:200px;
    
  &.third {
    margin-top: -30px;
  }
    

  @media (max-width: 460px) {
    width: 230px;
    &.first {
      margin-top: 0px;
    }
    &.second {
      margin-top: 0px;
    }
    &.third {
      margin-top: 0px;
    }
    &.fourth {
    }
  
  }
`;

export const TeamMemberContentDiv = styled.div<LocationProps>`
  align-items: center;
  justify-content: end;
  display: flex;
  height: 100%;
  padding-bottom: 20px;
  box-sizing: border-box;
  background: var(--sixth-gradient8);

  flex-direction: column;
  color: white;
  font-size: 1.2rem;
  font-weight: 500;
  transition: all 0.7s;
  cursor: pointer;
  text-shadow: -.5px 0 black, 0 .5px black, .5px 0 black, 0 -.5px black;

  > div {
    &.role {
      text-align:left;
      font-size: 1.2rem;
      text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;

    }
    &.bold{
      color: var(--text-blue);
      text-transform:uppercase;
      text-shadow: 0;
    }
  }

  &:hover {
    padding-bottom: 59px;
    background: var(--sixth-gradient8);
  }
`;

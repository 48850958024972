import { CustomerBodyHead } from "../../styles/customerstyles/customerComponentStyles";
import {
  NewsRoomHero,
  NewsRoomHeroContent,
  NewsRoomMainDiv,
  Newsroom,
  NewsroomContent,
  NewsroomCover,
  NewsroomDiv,
  NewsroomFilter,
  NewsroomIcon,
  NewsroomInfo,
  NewsroomNews,
  NewsroomNewsHero,
  NewsroomType,
} from "../../styles/newsroomstyles/newsromStyles";
import { Button, TextHeader } from "../../styles/reusable";
import Footer from "../reuseable/footer";
import AppHeader from "../reuseable/header";
import { GrAnnounce } from "react-icons/gr";

const NewsRoomComponents = () => {
  return (
    <NewsRoomMainDiv>
      <NewsRoomHero>
        <AppHeader
          hideContact={false}
          logo={"/images/sixthBlue.png"}
          top="0.5rem"
          width="10rem"
          mobileWidth="8rem"
          invert={true}
        />
        <NewsRoomHeroContent>
          <TextHeader> Newsroom</TextHeader>

          <NewsroomDiv>
            <Newsroom src="/images/vendy.png">
              <div>
                {" "}
                <NewsroomIcon>
                  <GrAnnounce size={40} />
                </NewsroomIcon>
              </div>
            </Newsroom>

            <div style={{ display: "flex", alignItems: "center" }}>
              <NewsroomCover>
                <NewsroomInfo>
                  <NewsroomType> PRESS RELEASES</NewsroomType>

                  <TextHeader>
                    {" "}
                    Sixth Strengthens its Trust Platform with a Wave of
                    Innovations
                  </TextHeader>
                  <p>
                    {" "}
                    Sixth has announced product innovations designed to
                    optimize payments, strengthen visibility and control and
                    drive scale for the world’s largest merchants.
                  </p>
                </NewsroomInfo>
              </NewsroomCover>
            </div>
          </NewsroomDiv>
        </NewsRoomHeroContent>
      </NewsRoomHero>
      <NewsroomFilter>
        <TextHeader style={{ fontSize: "2rem" }}> Filter by</TextHeader>
      </NewsroomFilter>
      <NewsroomContent>
        <NewsroomNews>
          <NewsroomNewsHero src="/images/venco.png">
            <NewsroomIcon>
              <GrAnnounce size={40} />
            </NewsroomIcon>
          </NewsroomNewsHero>
          <NewsroomNewsHero className="news">
            <section>
              {" "}
              <NewsroomType> PRESS RELEASES</NewsroomType>{" "}
              <TextHeader>
                {" "}
                Sixth Strengthens its Trust Platform with a Wave of Innovations
              </TextHeader>
              <p>
                {" "}
                Sixth has announced product innovations designed to optimize
                payments, strengthen visibility and control and drive scale for
                the world’s largest merchants.
              </p>
            </section>
            
          </NewsroomNewsHero>
        </NewsroomNews>
      </NewsroomContent>
      <Footer></Footer>
    </NewsRoomMainDiv>
  );
};

export default NewsRoomComponents;

import {
  CareerHero,
  CareerHeroContent,
  CareerHowWeWorkDiv,
  CareerMainDiv,
  CareerWorkMissionDiv,
  CareerWorkMissionIcon,
  CareerWorkWithUsDiv,
} from "../../styles/careerstyles/careerStyles";
import { Button, SmallGradientHero, TextHeader } from "../../styles/reusable";
import AppHeader from "../reuseable/header";
import { LuHeartHandshake } from "react-icons/lu";
import { AiOutlineAim } from "react-icons/ai";
import { GiLaurelsTrophy } from "react-icons/gi";
import { FaAssistiveListeningSystems } from "react-icons/fa";
import { GiMuscleUp } from "react-icons/gi";
import { BiSearchAlt } from "react-icons/bi";
import { SiGooglemeet } from "react-icons/si";
import { AiOutlineFieldTime } from "react-icons/ai";
import { MdOutlineGroups } from "react-icons/md";
import { LuBrain } from "react-icons/lu";

import {
  TeamMemberContainer,
  TeamMemberContentDiv,
  TeamMemberDiv,
  TeamMember,
} from "../../styles/aboutusstyles/aboutUsStyle";
import Footer from "../reuseable/footer";

const CareerComponents = () => {
  return (
    <CareerMainDiv>
      <CareerHero>
        <AppHeader
          hideContact={false}
          logo={"/images/sixthBlue.png"}
          top="0.7rem"
          width="7rem"
          invert={true}
          mobileWidth="8rem"
        />
        <CareerHeroContent>
          <TextHeader>Digital commerce is built on trust.</TextHeader>
          <h2> So are great companies.</h2>
          <Button
            buttonWidth="300px"
            className="expandWidth"
            bg="var(--text-blue)"
            hoverColor=" white"
            color="white"
            hoverBg="var(--text-blue)"
          >
            view all openings
          </Button>
        </CareerHeroContent>
      </CareerHero>
      <TextHeader style={{ textAlign: "center" }}> Our Team</TextHeader>
      <TeamMemberContainer>
        <div>
          <TeamMemberDiv>
            {team.map((item, index) => (
              <div>
                <TeamMember className={item.class} src={item.image}>
                  <TeamMemberContentDiv>
                    {" "}
                    <div className="role">{item.name}</div>{" "}
                    <div className="role">{item.role}</div>
                  </TeamMemberContentDiv>{" "}
                </TeamMember>
              </div>
            ))}
          </TeamMemberDiv>
        </div>
      </TeamMemberContainer>
      <CareerWorkWithUsDiv>
        <TextHeader> What we believe</TextHeader>
        <p>
          You could say that we’ve formed a circle of trust that includes our
          employees, customers and partners. And that’s because every action we
          take is informed by six values—and yes, they spell IMPACT!
        </p>
        <div>
          {data.map((item, index) => (
            <CareerWorkMissionDiv>
              <CareerWorkMissionIcon> {item.icon}</CareerWorkMissionIcon>

              <h2> {item.name} </h2>
              <p> {item.describe} </p>
            </CareerWorkMissionDiv>
          ))}
        </div>
      </CareerWorkWithUsDiv>
      <CareerHowWeWorkDiv>
        <TextHeader>How we work</TextHeader>
        <p>
          Here are some of the methods and milestones that define our work
          culture
        </p>
        <div>
          {workethics.map((item, index) => (
            <SmallGradientHero src={item.image}>
              <div>
                <CareerWorkMissionIcon> {item.icon} </CareerWorkMissionIcon>
                <h1> {item.name}</h1>
                <p> {item.describe}</p>
              </div>
            </SmallGradientHero>
          ))}
        </div>
      </CareerHowWeWorkDiv>
      <CareerWorkWithUsDiv>
        <TextHeader> Come work with us</TextHeader>
        <p>
        Help us make an IMPACT by building trust between brands and consumers
        </p>
      
          <Button
            className="expandWidth"
            bg="var(--text-blue)"
            hoverColor=" white"
            color="white"
            hoverBg="var(--text-blue)"
            padding="0px"
            
          >
            view all openings
          </Button>
        
      </CareerWorkWithUsDiv>
      <Footer></Footer>
    </CareerMainDiv>
  );
};
const team = [
  {
    name: "Adewole Opeyemi",
    role: "Ceo",
    image: "/../images/opeyemi.jpg",
    class: "fourth",
  },
  {
    name: "Falade Bisola ",
    role: "Software Engineer",
    image: "/images/bisola.jpg",
    class: " second",
  },
  {
    name: "Balogun Precious",
    role: "Cto",
    image: "/images/balogun.jpg",
    class: "fourth",
  },
  {
    name: " Showemimo King",
    role: "Software Engineer",
    image: "/images/king.jpg",
    class: "first",
  },
];
const data = [
  {
    name: "Inclusive",
    describe:
      "By creating an environment where everyone feels they belong, we can win as one team",
    icon: <LuHeartHandshake size={39} />,
  },
  {
    name: "Mission-driven",
    describe:
      "We have a clear sense of purpose and are willing to go the extra mile for customers",
    icon: <AiOutlineAim size={39} />,
  },
  {
    name: "Principled",
    describe: "When we make decisions, we do what’s right",
    icon: <GiLaurelsTrophy size={39} />,
  },
  {
    name: "Attentive",
    describe: "We are present and always listen first",
    icon: <FaAssistiveListeningSystems size={39} />,
  },
  {
    name: "Courageous",
    describe:
      "We dare to take risks and view mistakes as learning opportunities",
    icon: <GiMuscleUp size={39} />,
  },
  {
    name: "Transparent",
    describe:
      "We openly share ideas, information and opinions because we trust each other",
    icon: <BiSearchAlt size={39} />,
  },
];
const workethics = [
  {
    name: "Stand Ups",
    describe:
      "When possible, we love to gather the entire company in one place for a physical meeting to align, learn and celebrate together",
    icon: <SiGooglemeet size={40} />,
    image:"/images/standup.jpg"
  },
  {
    name: "5 Day Weeks",
    describe:
      "Time away from work is critical to stay fresh. Fridays end at 1PM",
    icon: <AiOutlineFieldTime size={40} />,
    image:"/images/Working solo.jpg"

  },
  {
    name: "Hybrid Environment",
    describe:
      "Combine the benefits of in-office collaboration with the flexibility of remote work. Team members are invited to work from the office at least 3 days per week",
    icon: <MdOutlineGroups size={40} />,
    image:"/images/hybrid.jpg"

  },
  {
    name: "Career Development",
    describe:
      "We offer training programs,and ensure internal mobility so employees can have multiple careers all at Sixth",
    icon:<LuBrain size={40} />,
    image:"/images/learn.jpg"

  },
];
export default CareerComponents;

import NotFoundComponent from "../components/notfoundcomponent";

const NotFoundPage = () => {
  return (
    <>
      <div className="main-widget">
        <NotFoundComponent />
      </div>
    </>
  );
};

export default NotFoundPage;

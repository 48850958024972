import styled from "styled-components";
interface MainProp {
  mainMidth?: "fit-content" | "100%";
  objOpacity?: number;
  delay?: string;
}

export const RevealMainDiv = styled.div<MainProp>`
  position: relative;
  overflow: hidden;
  opacity: ${(p) => p.objOpacity};
  filter: blur(10px);
  transition: all 1.2s;

  &.left {
    transform: translateX(-100%);
    @media (max-width: 840px) {
      opacity: 1;
      filter: blur(0px);
      transform: translateX(0%);
      transform: translateY(0%);
    }
  }

  &.right {
    transform: translateX(100%);
    @media (max-width: 840px) {
      opacity: 1;
      filter: blur(0px);
      transform: translateX(0%);
      transform: translateY(0%);
    }
  }

  &.bottom {
    transform: translateY(100%);
    @media (max-width: 840px) {
      opacity: 1;
      filter: blur(0px);
      transform: translateX(0%);
      transform: translateY(0%);
    }
  }
  &.top {
    transform: translateY(-100%);
    @media (max-width: 840px) {
      opacity: 1;
      filter: blur(0px);
      transform: translateX(0%);
      transform: translateY(0%);
    }
  }

  &.show {
    transition-delay: ${(p) => (p.delay ? p.delay : "0s")};
    opacity: 1;
    filter: blur(0px);
    transform: translateX(0%);
    transform: translateY(0%);
  }

  @media (max-width: 840px) {
    opacity: 1;
    filter: blur(0px);
    transform: translateX(0%);
    transform: translateY(0%);
  }
`;

import styled from "styled-components";

export const ContactUsMainDiv = styled.div`

`

export const ContactUsHero = styled.div`
  min-height: 20vh;
  background: var(--sixthBlue);
  

`;


export const ContactUsHeroContent = styled.div`
  min-height: 20vh;
  background: var(--sixthBlue);
  text-align:center;
  color:white;
  h1{
    font-size: 3.625rem;
    line-height: 1.125;
    color:white;
  }
  p{
    
    font-size: 1.25rem;
    line-height: 1.375;
  }
  
@media (max-width: 460px) {
    padding:3rem;
 
  }
`;



export const ContactUsBodyHero = styled.div`
  display:flex;
  gap:50px;
  flex-wrap:wrap;
  justify-content:center;
  margin:8rem 10%;
  box-sizing:border-box;
  @media (max-width: 460px) {
    margin:8rem 1rem;

  
  }
`;



export const ContactUsIcon = styled.div`
background: var(--sixthBlue);
width: 70px;
height:70px;
display:flex;
align-items:center;
justify-content:center;
border-radius:80px;
 
`;
export const ContactUsBodyContent = styled.div`
align-items:center;
p{
    margin-bottom:3rem;
}
a{
    align-item:center;
    justify-contents:center;
    
  
    font-size:1.75rem;
    font-weight:500;
    
&:hover{
    
        
        text-decoration:underline;

}
    
}
>div{
    display:flex;
    align-items:center;
    justify-content:center;
    gap:5px;


    
}
@media (max-width: 460px) {
    a{
        font-size:1rem;
        


  
  }

 
`;
import AbouUsComponent from "../components/aboutUs/aboutUsComponent"

const AboutUsPage =() =>{

    return(
        <>
        <AbouUsComponent></AbouUsComponent>
        
        </>
    )
}

export default AboutUsPage
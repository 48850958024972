import {
    EventsHero,
    EventsHeroContent,
    EventsMainDiv,
    Events,
    EventsContent,
    EventsCover,
    EventsDiv,
    EventsFilter,
    EventsIcon,
    EventsInfo,
    EventsNews,
    EventsNewsHero,
    EventsType,
  } from "../../styles/eventsstyles/eventStyles";
  import { Button, TextHeader } from "../../styles/reusable";
  import Footer from "../reuseable/footer";
  import AppHeader from "../reuseable/header";
  import { GrAnnounce } from "react-icons/gr";
const EventsComponents = ()=>{
    return(

        <EventsMainDiv>
      <EventsHero>
        <AppHeader
          hideContact={false}
          logo={"/images/sixthBlue.png"}
          top="0.5rem"
          width="10rem"
          mobileWidth="8rem"
          invert={true}
        />
        <EventsHeroContent>
          <TextHeader> Upcoming Events</TextHeader>
          <p>Sharing ideas and best practices around fraud prevention and digital commerce</p>

          <EventsDiv>
            <Events src="/images/vendy.png">
              <div>
                {" "}
                <EventsIcon>
                  <GrAnnounce size={40} />
                </EventsIcon>
              </div>
            </Events>

            <div style={{ display: "flex", alignItems: "center" }}>
              <EventsCover>
                <EventsInfo>
                  <EventsType>23 Nov 2024</EventsType>

                  <TextHeader>
                    {" "}
                    Sixth Strengthens its Trust Platform with a Wave of
                    Innovations
                  </TextHeader>
                  <p>
                    {" "}
                    Sixth has announced product innovations designed to
                    optimize payments, strengthen visibility and control and
                    drive scale for the world’s largest merchants.
                  </p>
                </EventsInfo>
              </EventsCover>
            </div>
          </EventsDiv>
        </EventsHeroContent>
      </EventsHero>
      <EventsFilter>
        <TextHeader style={{ fontSize: "2rem" }}> Filter by</TextHeader>
      </EventsFilter>
      <EventsContent>
        <EventsNews>
          <EventsNewsHero src="/images/venco.png">
            <EventsIcon>
              <GrAnnounce size={40} />
            </EventsIcon>
          </EventsNewsHero>
          <EventsNewsHero className="news">
            <section>
              {" "}
              <EventsType> PRESS RELEASES</EventsType>{" "}
              <TextHeader>
                {" "}
                Sixth Strengthens its Trust Platform with a Wave of Innovations
              </TextHeader>
              <p>
                {" "}
                Sixth has announced product innovations designed to optimize
                payments, strengthen visibility and control and drive scale for
                the world’s largest merchants.
              </p>
            </section>
            
          </EventsNewsHero>
        </EventsNews>
      </EventsContent>
      <Footer></Footer>
    </EventsMainDiv>
    )
}

export default EventsComponents;
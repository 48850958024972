
import {
  Button,
  Carousel,
  CarouselFlexedData,
  TextHeader,
} from "../../styles/reusable";
import { ImgHero } from "../../styles/homeComps/homeHeroStyles";
import { useEffect, useRef, useState } from "react";

const Testimonial = () => {
  const carouselRef = useRef<HTMLDivElement>(null);
  const [focusedIndex, setFocusedIndex] = useState(0);
  const itemsId = ["bridgecard", "mono", "venco"];
  useEffect(() => {
    const handleScroll = () => {
      if (carouselRef.current) {
        const containerWidth = carouselRef.current.clientWidth;
        console.log("All items are ", itemsId.length);
        for (let i = 0; i < itemsId.length; i++) {
          const item = document.getElementById(itemsId[i]);
          if (item) {
            const itemRect = item.getBoundingClientRect();

            if (itemRect.left >= 0 && itemRect.right <= containerWidth) {
              setFocusedIndex(i);
              break;
            }
          }
        }
      }
    };

    if (carouselRef.current) {
      carouselRef.current.addEventListener("scroll", handleScroll);
      carouselRef.current.addEventListener("hover", handleScroll);
    }

    return () => {
      if (carouselRef.current) {
        carouselRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const scrollRight = () => {
    if (carouselRef.current) {
      const firstItemWidth = carouselRef.current.children[0].clientWidth;
      carouselRef.current.scrollBy({
        left: firstItemWidth + 16, // Scroll by the width of one item plus the margin
        behavior: "smooth",
      });
    }
  };
  return (
    <>
      <TextHeader
        style={{ textAlign: "center", color: "#03045E", fontSize: "30px" }}
      >
        More reasons to be using Sixth?
      </TextHeader>
      <Carousel ref={carouselRef}>
        <CarouselFlexedData
          bg="var(--sixthLightBlue)"
          radius="25px"
          width="50%"
          height="380px"
          align="center"
          style={{
            flexShrink: 0,
            flex: "0 0 auto",
            paddingLeft: "24px",
            paddingRight: "24px",
          }}
          className={focusedIndex == 0 ? "animationd" : ""}
          id="bridgecard"
        >
          <section style={{ padding: "18px", width: "100%" }}>
            <TextHeader>
              Learn how Bridgecard uses Sixth to reduce 100% of their duplicate
              transactions monthly
              <br />
            </TextHeader>

            <p>
              You could be at risk of loosing so much more, check out how
              industry leading Fintech companies combat chargeback fraud and
              duplicate transactions.
            </p>

            <Button
              bg="transparent"
              color="var(--text-blue)"
              hoverBg="var(--text-blue)"
              hoverColor="white"
              border="1px solid #03045E"
              style={{ marginTop: "17px" }}
            >
              READ NOW
            </Button>
          </section>

          <ImgHero style={{ minWidth: "30%" }}>
            <img src="/images/Tunde Bridgecard.jpeg" alt="Card" />
            <h4 style={{ color: "#03045E" }}>2 mins read</h4>
          </ImgHero>
        </CarouselFlexedData>
        <CarouselFlexedData
          bg="var(--sixthLightBlue)"
          radius="25px"
          width="50%"
          height="380px"
          align="center"
          style={{
            scrollSnapAlign: "center",
            flexShrink: 0,
            flex: "0 0 auto",
            paddingLeft: "24px",
            paddingRight: "24px",
            marginLeft: "0%",
          }}
          className={focusedIndex == 1 ? "animationd" : ""}
          id="mono"
        >
          <section style={{ padding: "18px", width: "100%" }}>
            <TextHeader>
              Here's why Abdul CEO of Mono thinks you should be using Sixth for
              safe guarding your transaction services
            </TextHeader>

            <p>
              Here's why industry leading expert AbdulHamid Hassan (CEO of Mono)
              thinks Sixth should be your first go to for preventing money lost
              to duplicate transactions.
            </p>

            <Button
              bg="transparent"
              color="var(--text-blue)"
              hoverBg="var(--text-blue)"
              hoverColor="white"
              border="1px solid #03045E"
              style={{ marginTop: "17px" }}
            >
              READ NOW
            </Button>
          </section>

          <ImgHero style={{ minWidth: "30%" }}>
            <img src="/images/Abdul Mono.jpeg" alt="Card" />
            <h4 style={{ color: "#03045E" }}>3 min read</h4>
          </ImgHero>
        </CarouselFlexedData>
        <CarouselFlexedData
          bg="var(--sixthLightBlue)"
          radius="25px"
          width="50%"
          height="380px"
          align="center"
          style={{
            flexShrink: 0,
            flex: "0 0 auto",
            paddingLeft: "24px",
            paddingRight: "24px",
          }}
          className={focusedIndex == 2 ? "animationd" : ""}
          id="venco"
        >
          <section style={{ padding: "18px", width: "100%" }}>
            <TextHeader>
              Usecase beyond Fintech, Learn how Venco, a real estate firm uses
              Sixth to safeguard their API endpoints from Abuse and malicious
              exploitations.
            </TextHeader>

            <p>
              With Sixth, Venco has significantly enhanced the security of their
              digital assets, safeguarding their API endpoints from malicious
              activities, reinforcing their reputation as a reliable and secure
              real estate firm.
            </p>

            <Button
              bg="transparent"
              color="var(--text-blue)"
              hoverBg="var(--text-blue)"
              hoverColor="white"
              border="1px solid #03045E"
              style={{ marginTop: "17px" }}
            >
              READ NOW
            </Button>
          </section>

          <ImgHero style={{ minWidth: "30%" }}>
            <img src="/images/chude.jpeg" alt="Card" />
            <h4 style={{ color: "#03045E" }}>2 min read</h4>
          </ImgHero>
        </CarouselFlexedData>
      </Carousel>
    </>
  );
};

export default Testimonial;

//const data = [
//  {
//    review:
//      " With Sixth, we were able to integrate their SDKs and makee sure our systems were secure all round and also easily track down attempts of breaches on our systems as Sixth made this very easy by making detailed reports to our slack channels. A problem which was a huge task to solve before the integration of Sixth.",
//    user: "Tunde, CEO of Bridgecard",
//    stars: 5,
//    image: "/images/Tunde Bridgecard.jpeg",
//  },
//  {
//    review:
//      " Sixth is solving a problem that has plauged the African eco-system for quite a while. With the rise in fraud in the African Fintech eco-system, Sixth is tackling this problem heads on and the execution and timing of the product couldn't be better executed. ",
//    user: "Abdul, CEO of Mono ",
//    stars: 2,
//    image: "/images/Abdul Mono.jpeg",
//  },
//];
//

import { Channel } from "diagnostics_channel";
import {
  ChatWithUsFormGroupField,
  ChatWithUsFormInput,
  ChatWithUsFormLabel,
  ChatWithUsMain,
  ChatWithUsMainBody,
  ChatWithUsMainDiv,
  ChatWithUsMainField,
  ChatWithUsMainHeader,
  
} from "../../styles/catStyles/ChatWithUsStyles";
import { Button, TextHeader } from "../../styles/reusable";

const ChatWithUsCompnents = () => {
  return (
    <ChatWithUsMainDiv>
      <ChatWithUsMain>
        <ChatWithUsMainHeader>
          <TextHeader style={{fontSize:"1.7rem", lineHeight:"36px"}}>
            {" "}
            See how Sixth can make your
            <br />
            systems more Relable
          </TextHeader>
        </ChatWithUsMainHeader>
        <ChatWithUsMainBody>
          <TextHeader >
            Submit your details below to <br />
            contact our sales team
          </TextHeader>
          <ChatWithUsMainField>
            
            
            <ChatWithUsFormGroupField>
              <ChatWithUsFormInput
                type="input"
                placeholder="firstname"
                name="firstname"
                id="firstname"
                required
              />
              <ChatWithUsFormLabel  htmlFor="firstname">
                
              First Name
              </ChatWithUsFormLabel>
            </ChatWithUsFormGroupField>

            <ChatWithUsFormGroupField>
              <ChatWithUsFormInput
                type="input"
                placeholder="lastname"
                name="lastname"
                id="lastname"
                required
              />
              <ChatWithUsFormLabel  htmlFor="lastname">
                
              Last Name
              </ChatWithUsFormLabel>

              
            </ChatWithUsFormGroupField>

              
            <ChatWithUsFormGroupField>
              <ChatWithUsFormInput
                type="input"
                placeholder="email"
                name="email"
                id="email"
                required
              />
              <ChatWithUsFormLabel  htmlFor="email">
                
              Business Email
              </ChatWithUsFormLabel>
            </ChatWithUsFormGroupField>

            <ChatWithUsFormGroupField>
              <ChatWithUsFormInput
                type="tel"
                placeholder="lastname"
                name="phone"
                id="phone"
                inputMode="tel"
                autoComplete="tel"
                required
              />
              <ChatWithUsFormLabel  htmlFor="phone">
                
              Phone Number
              </ChatWithUsFormLabel>

              
            </ChatWithUsFormGroupField>

               
           
           
          
          
            <div>
              <Button bg="var(--sixthBlue)" color="white"
                hoverBg="var(--sixthBlue)"
                hoverColor=" var(--text-blue)">
                Contact us
              </Button>
            </div>
            <p style={{fontSize:"12px"}}>
              {" "}
              Sixth needs the contact information you provide to us to contact
              you about our products and services. You may unsubscribe from
              these communications at anytime. For information on how to
              unsubscribe, as well as our privacy practices and commitment to
              protecting your privacy, check out our Privacy Policy.
            </p>
          </ChatWithUsMainField>
        </ChatWithUsMainBody>
      </ChatWithUsMain>
    </ChatWithUsMainDiv>
  );
};
const countries =[
"Afghanistan",
"Albania",
"Algeria",
"Andorra",
"Angola",
"Antigua and Barbuda",
"Argentina",
"Armenia",
"Australia",
"Austria",
"Azerbaijan",
"The Bahamas",
"Bahrain",
"Bangladesh",
"Barbados",
"Belarus",
"Belgium",
"Belize",
"Benin",
"Bhutan",
"Bolivia",
"Bosnia and Herzegovina",
"Botswana",
"Brazil",
"Brunei",
"Bulgaria",
"Burkina Faso",
"Burundi",
"Cabo Verde",
"Cambodia",
"Cameroon",
"Canada",
"Central African Republic",
"Chad",
"Chile",
"China",
"Colombia",
"Comoros",
"Congo (Democratic Republic)",
"Congo (Republic)",
"Costa Rica",
"Côte d’Ivoire",
"Croatia",
"Cuba",
"Cyprus",
"Czech Republic",
"Denmark",
"Djibouti",
"Dominica",
"Dominican Republic",
"East Timor (Timor-Leste)",
"Ecuador",
"Egypt",
"El Salvador",
"Equatorial Guinea",
"Eritrea",
"Estonia",
"Eswatini",
"Ethiopia",
"Fiji",
"Finland",
"France",
"Gabon",
"The Gambia",
"Georgia",
"Germany",
"Ghana",
"Greece",
"Grenada",
"Guatemala",
"Guinea",
"Guinea-Bissau",
"Guyana",
"Haiti",
"Honduras",
"Hungary",
"Iceland",
"India",
"Indonesia",
"Iran",
"Iraq",
"Ireland",
"Israel",
"Italy",
"Jamaica",
"Japan",
"Jordan",
"Kazakhstan",
"Kenya",
"Kiribati",
"Korea (North)",
"Korea (South)",
"Kosovo",
"Kuwait",
"Kyrgyzstan",
"Laos",
"Latvia",
"Lebanon",
"Lesotho",
"Liberia",
"Libya",
"Liechtenstein",
"Lithuania",
"Luxembourg",
"Madagascar",
"Malawi",
"Malaysia",
"Maldives",
"Mali",
"Malta",
"Marshall Islands",
"Mauritania",
"Mauritius",
"Mexico",
"Micronesia (Federated States)",
"Moldova",
"Monaco",
"Mongolia",
"Montenegro",
"Morocco",
"Mozambique",
"Myanmar (Burma)",
"Namibia",
"Nauru",
"Nepal",
"Netherlands",
"New Zealand",
"Nicaragua",
"Niger",
"Nigeria",
"North Macedonia",
"Norway",
"Oman",
"Pakistan",
"Palau",
"Panama",
"Papua New Guinea",
"Paraguay",
"Peru",
"Philippines",
"Poland",
"Portugal",
"Qatar",
"Romania",
"Russia",
"Rwanda",
"Saint Kitts and Nevis",
"Saint Lucia",
"Saint Vincent and the Grenadines",
"Samoa",
"San Marino",
"Sao Tome and Principe",
"Saudi Arabia",
"Senegal",
"Serbia",
"Seychelles",
"Sierra Leone",
"Singapore",
"Slovakia",
"Slovenia",
"Solomon Islands",
"Somalia",
"South Africa",
"Spain",
"Sri Lanka",
"Sudan",
"Sudan (South)",
"Suriname",
"Sweden",
"Switzerland",
"Syria",
"Taiwan",
"Tajikistan",
"Tanzania",
"Thailand",
"Togo",
"Tonga",
"Trinidad and Tobago",
"Tunisia",
"Turkey",
"Turkmenistan",
"Tuvalu",
"Uganda",
"Ukraine",
"United Arab Emirates",
"United Kingdom",
"United States",
"Uruguay",
"Uzbekistan",
"Vanuatu",
"Vatican City",
"Venezuela",
"Vietnam",
"Yemen",
"Zambia",
"Zimbabwe",] 
export default ChatWithUsCompnents;

import {
  CustomerBody,
  CustomerBodyContent,
  CustomerBodyHead,
  CustomerBodyHeadSearch,
  CustomerCover,
  CustomerDiv,
  CustomerHeadDiv,
  CustomerHero,
  CustomerHeroLeft,
  CustomerHeroRight,
  CustomerInfo,
  CustomerInfoAbsolute,
  CustomerInfoCard,
  CustomerMainDiv,
  CustomerNews,
  CustomerNewsType,
} from "../../styles/customerstyles/customerComponentStyles";
import { SupplText, TextHeader } from "../../styles/reusable";
import ChatWithUsCompnents from "../catComponents/chatWithUsCmpnents";
import Reveal from "../reuseable/Reveal";
import Footer from "../reuseable/footer";
import AppHeader from "../reuseable/header";

const CustomerComponent = () => {
  return (
    <CustomerMainDiv>
      <CustomerHeadDiv>
        <AppHeader
          hideContact={false}
          logo={"/images/sixthBlue.png"}
          top="0.7rem"
          width="7rem"
          invert={true}
          mobileWidth="8rem"
          
        />

        <CustomerDiv>
          <CustomerHero>
            <CustomerHeroLeft>
              <Reveal direction="bottom">
                <TextHeader style={{ color: "#03045E", fontWeight: "1000" }}>
                  The Trust Platform Powering Global Financial Operations
                </TextHeader>
              </Reveal>
              <Reveal direction="bottom" delay=".3s">
                <SupplText
                  style={{
                    color: "#03045E",
                    fontWeight: "600",
                    fontSize: "1.1rem",
                  }}
                >
                  Discover How Leading Financial Institutions Enhance Security
                  and Drive Growth with Sixth
                </SupplText>
              </Reveal>
            </CustomerHeroLeft>

            <CustomerHeroRight>
              <iframe
                src="https://www.youtube.com/embed/GVt_IlNhkwU?si=uKouDD9rbyUgaTyl"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              ></iframe>
            </CustomerHeroRight>
          </CustomerHero>
          <Reveal delay="0.3s">
            <CustomerInfo>
              <CustomerInfoCard>
                <CustomerInfoAbsolute>
                  <img
                    src="/images/money.svg"
                    style={{ marginLeft: "0.7rem" }}
                  ></img>
                </CustomerInfoAbsolute>
                <TextHeader
                  style={{
                    color: "white",
                    fontSize: "2rem",
                    paddingBottom: "30px",
                    marginLeft: "1rem",
                  }}
                >
                  $3M
                </TextHeader>
                <SupplText
                  style={{
                    color: "white",
                    fontSize: "0.8em",
                    marginLeft: "1rem",
                    fontWeight: "600",
                    lineHeight: "24px",
                  }}
                >
                  saved from duplicate transactions and <br />
                  charge-back fraud
                </SupplText>
              </CustomerInfoCard>

              <CustomerInfoCard>
                <CustomerInfoAbsolute>
                  <img
                    src="/images/shield.svg"
                    style={{ marginLeft: "0.8rem" }}
                  ></img>
                </CustomerInfoAbsolute>
                <TextHeader
                  style={{
                    color: "white",
                    fontSize: "2rem",
                    paddingBottom: "30px",
                    marginLeft: "1rem",
                  }}
                >
                  17.5k+
                </TextHeader>
                <SupplText
                  style={{
                    color: "white",
                    fontSize: "0.8em",
                    marginLeft: "1rem",
                    fontWeight: "600",
                    lineHeight: "24px",
                  }}
                >
                  Attempts at duplicate transactions and chargeback fraud.
                </SupplText>
              </CustomerInfoCard>
              <CustomerInfoCard>
                <CustomerInfoAbsolute>
                  <img
                    src="/images/profile.svg"
                    style={{ marginLeft: "0.8rem" }}
                  ></img>
                </CustomerInfoAbsolute>
                <TextHeader
                  style={{
                    color: "white",
                    fontSize: "2rem",
                    paddingBottom: "30px",
                    marginLeft: "1rem",
                  }}
                >
                  34
                </TextHeader>
                <SupplText
                  style={{
                    color: "white",
                    fontSize: "1.0rem",
                    marginLeft: "1rem",
                  }}
                >
                  Satisifed companies offering financial services globally
                </SupplText>
              </CustomerInfoCard>
            </CustomerInfo>
          </Reveal>
        </CustomerDiv>

        <hr></hr>
      </CustomerHeadDiv>
      <CustomerBody>
        <CustomerBodyHead>
          <TextHeader style={{ fontSize: "2rem" }}> Filter by</TextHeader>
          <CustomerBodyHeadSearch placeholder="Type to search"></CustomerBodyHeadSearch>
        </CustomerBodyHead>
        <CustomerBodyContent>
          {row2.map((item, index) => (
            <CustomerNews src={item.img} id="index">
              <CustomerCover>
                <CustomerNewsType>
                  <p>{item.title}</p>
                </CustomerNewsType>
                <h2 > {item.news} </h2>
              </CustomerCover>
            </CustomerNews>
          ))}
        </CustomerBodyContent>
        <CustomerBodyContent>
          {data.map((item, index) => (
            <CustomerNews src={item.img} id="index">
              <CustomerCover>
                <CustomerNewsType>
                  <p>{item.title}</p>
                </CustomerNewsType>
                <h2 > {item.news} </h2>
              </CustomerCover>
            </CustomerNews>
          ))}
        </CustomerBodyContent>
        <ChatWithUsCompnents />
 
        
      </CustomerBody>

      <Footer></Footer>
    </CustomerMainDiv>
  );
};
const data = [
 
  {
    img: "/images/venmob_screenshot.png",
    title: "case study",
    news: "Venmob",
  },

  {
    img: "/images/vendy.png",
    title: "case study",
    news: "Vendy",
  },
  {
    img: "/images/mainHerosmall.png",
    title: "case study",
    news: "+29 more companies",
  },

];
const row2 = [
 

  {
    img: "/images/venco_screenshot.png",
    title: "case study",
    news: "Venco",
  },

  {
    img: "/images/mono_screenshot.png",
    title: "case study",
    news: "Mono",
  },

  {
    img: "/images/bridgecard_screenshot.png",
    title: "case study",
    news: "Bridgecard",
  },
];

export default CustomerComponent;

import {
  ContactUsDiv,
  PrivacyDiv,
  PrivacyHero,
  PrivacyHeroContent,
  PrivacyMainDiv,
  PrivacyMissionDiv,
  PrivacyMissionIcon,
} from "../../styles/privacystyles/privacyStyle";
import { Button, TextHeader } from "../../styles/reusable";
import AppHeader from "../reuseable/header";
import { GrSecure } from "react-icons/gr";
import { LiaCookieSolid } from "react-icons/lia";
import { GrServices } from "react-icons/gr";
import { SiAwssecretsmanager } from "react-icons/si";
import { TbReportAnalytics } from "react-icons/tb";
import { PiSealQuestion } from "react-icons/pi";
import { CgAwards } from "react-icons/cg";
import { GoChecklist } from "react-icons/go";
import { BsChatSquareQuote } from "react-icons/bs";

import Footer from "../reuseable/footer";
import { useNavigate } from "react-router-dom";




const PrivacyCompnentPage = () => {
  const navigate = useNavigate()
  return (
    <>
      <PrivacyMainDiv>
      <AppHeader
            hideContact={false}
            logo={"/images/sixthBlue.png"}
            top="0.7rem"
            width="7rem"
            mobileWidth="8rem"
          />
        <PrivacyHero>
         
          <PrivacyHeroContent>
            <TextHeader>Privacy and Security Hub</TextHeader>
            <h2>
              {" "}
              At Sixth we strive to be the trust platform for digital commerce.
              This means that we want to provide clear information about how we
              keep your information secure and respect your data privacy. This
              page outlines how Sixth achieves those goals.
            </h2>
          </PrivacyHeroContent>
          <PrivacyDiv>
            {data.map((item, index) => (
              <PrivacyMissionDiv>
                <PrivacyMissionIcon>{item.icon}</PrivacyMissionIcon>
                <h2> {item.name} </h2>
                <p> {item.describe} </p>
                <Button className="expandWidth"  bg="transparent" color="white" hoverBg="transparent" border="1px solid white"  hoverColor="var(--sixthBlue)" >{item.button} </Button>
              </PrivacyMissionDiv>
            ))}
          </PrivacyDiv>
        </PrivacyHero>
        <ContactUsDiv>
          <div> <BsChatSquareQuote  size={50}/></div>
        
        <TextHeader>Have further questions? Please contact us.</TextHeader>
        
      
          <Button
            className="expandWidth"
            bg="var(--text-blue)"
            hoverColor=" white"
            color="white"
            hoverBg="var(--text-blue)"
            padding="1rem 3rem"
            onClick={(e)=>{
              navigate("/contact-us")

            }}
          
            
          >
           CONTACT US
          </Button>
        </ContactUsDiv>
        <Footer></Footer>
      </PrivacyMainDiv>
    </>
  );
};

const data = [
  {
    name: "Privacy Policy",
    describe:
      "This is where you can learn more about the data that Sixth collects, how we protect it, how we use it and your rights in relation to this data.",
    icon: <GrSecure size={50}/>,
    button: "LEARN MORE",
  },
  {
    name: "Services Policy ",
    describe:"This policy describes the personal data that we collect from our merchant in connection with the services that we provide, and how we protect, use and transfer that data.    ",
    icon:<GrServices size={50}/>,
    button: "LEARN MORE",
  },

  {
    name: "Cookie Policy",
    describe:"Sixth uses cookies to make our site work and provide you with a better experience. This policy explains what cookies are, the types of cookies we use and how we manage them.",
    icon:<LiaCookieSolid size={50}/>,
    button: "LEARN MORE",
  },

  {
    name: "Security",
    describe:"At Sixth security is core to everything we do. This page details how we safeguard your data.    ",
    icon:<SiAwssecretsmanager size={50}/>,
    button: "LEARN MORE",
  },

  {
    name: "Transparency Report  ",
    describe:"Sixth is committed to protecting the privacy of our customer's data. Here you can find a report which details any legitimate law enforcement or government entity requests for data that Sixth has received.",
    icon:<TbReportAnalytics size={50}/>,
    button: "LEARN MORE",
  },
  
  {
    name: "Terms of Use    ",
    describe:"A description of the acceptable terms and conditions for any user visiting a Sixth website.",
    icon:<GoChecklist size={50}/>,
    button: "TERMS OF USE",
  },
  {
    name: " Data Privacy Framework Statement ",
    describe:"As part of our ongoing commitment to privacy we have self-certified with the EU-US, UK-US and Swiss-US Data Privacy Frameworks. You can read our Data Privacy Framework Statement here    ",
    icon:<CgAwards size={50}/>,
    button: "DATA PRIVACY FRAMEWORK STATEMENT",
  },
  {
    name: "FAQs ",
    describe:"Here are the answers to some of the most commonly asked questions about Sixth’s privacy and security measures.    ",
    icon:<PiSealQuestion size={50}/>,
    button: "LEARN MORE",
  },
];

export default PrivacyCompnentPage;

const s=`






LEARN MORE

Have further questions? Ple	`;

import { styled } from "styled-components"

export const ServiceMain = styled.div`
    
    text-align:center;
    > div
    {
        align-items:center;
        display:flex;
        justify-content:center;
        flex-direction:column;

    }


    > h2 {
        font-style: normal;
        font-weight: 800;
        font-size: 35px;
        line-height: 48px;
        color: #000000;
        width:40%;
        margin:0 auto;
    }

    > p {
        padding:0 17%;
        font-weight: 300;
        font-size: 16px;
        line-height: 30px;
    }

    @media (max-width:600px){
        margin: 0rem auto 0 auto;
        padding: 2rem 0%;

        > h2 {
            font-size:25px;
            line-height:35px;
            width:90%;
        }

        > p {
            padding:0 5%;
        }
    }
`
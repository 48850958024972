import { useEffect,  } from "react";
import { RevealMainDiv } from "../../styles/RevealStyles";

interface Props {
  children: JSX.Element;
  width?: "fit-content" | "100%";
  direction?: string;
  delay?:string;
}

const observer = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    
    if (entry.isIntersecting) {
      entry.target.classList.add("show");
      
    }else{
        entry.target.classList.remove("show");

        
    }
  });
});

export const Reveal = ({
  children,
  direction,
  delay,
  width = "fit-content",
}: Props) => {

  useEffect(() => {
    var element = document.querySelectorAll(".hidden");

    if (element != null) {
        element.forEach((el) =>{
            observer.observe(el)
            

        });
        
    }
  }, []);

  return (
    <>
      <RevealMainDiv
        className={"hidden "+ direction} 
        mainMidth={width}
        delay={delay}
      >
        <div>{children}</div>
      </RevealMainDiv>
    </>
  );
};
export default Reveal;

import styled from "styled-components";

export const MainWrap = styled.nav`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 0;
  position: relative;
  z-index: 10000;
`;

interface PropsArgs {
  mobileBg?: string;
  mobileColor?: string;
}

export const MainHead = styled.div<PropsArgs>`
  width: 100%;
  padding: 1.2rem 4% 0.2rem 6.1%;
  display: flex;
  align-items: Center;
  justify-content: space-between;
  margin: 0 auto;

  > div {
    display: flex;
    align-items: Center;
    justify-content: center;
    gap: 100px;
  }

  @media (max-width: 728px) {
    > div {
      display: none;
    }
  }

  @media (max-width: 450px) {
    background: ${(p) => p.mobileBg || "transparent"};
    color: ${(p) => p.mobileColor || "var(--main-bg)"};
  }
`;

interface LogoProps {
  width: any;
  top: any;
  mobileWidth: any;
}

export const LogoImage = styled.img<LogoProps>`
  width: ${(p) => p.width || "5rem"};
  position: relative;
  top: ${(p) => p.top || "0"};

  @media (max-width: 450px) {
    width: ${(p) => p.mobileWidth || "3rem"};
  }
  color: white;
`;

export const HeaderBtn = styled.section`
  display: flex;
  gap: 0px;

  > button {
    font-weight: 700;
  }

  @media (max-width: 728px) {
    display: none;
  }
`;

export const MobileModal = styled.div`
  position: fixed;
  width: 86%;
  height: 100vh;
  top: 0;
  left: 0;
  background: var(--sixthLightBlue);
  padding: 3rem 7%;

  > i {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }
  > div{
    position: relative;

    height: 100vh;
    overflow-y:scroll;
    box-sizing:border-box;
    padding-bottom:10rem;


  }
`;


export const MobileNav = styled.div`


`;



export const MobileNavName = styled.div`
  display:flex;
  justify-content:space-between;
  align-items:center;
  color:var(--text-blue);
  p{
    padding:.25rem;
    margin:.25rem;
  }
  
`;


export const MobileNavSubNav = styled.div`
  display:flex;
  align-items:baseline;
  flex-direction:column;
  padding: 1rem 0 2rem;
  h1{
    opacity:.7;
    font-size:1.5rem;
    text-align:left;
  }

  color:var(--text-blue);
  p{
    padding:.25rem;
    margin:.25rem;
  }
  > div{
   
    display:flex;
    flex-direction:column;

  }

`;

export const MobileNavContents = styled.div`
  position: fixed;
  width: 86%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--sixthLightBlue);
  padding: 3rem 7%;

  > i {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }
`;
export const MobileMenuController = styled.section<PropsArgs>`
  display: none;
  color: ${(p) => p.mobileColor || "#fff"};

  @media (max-width: 728px) {
    display: block;
  }
`;

interface NavBoxProps {
  bg?: string;
  color?: string;
  border?: string;
  padding?: string;
  hoverColor?: string;
  hoverBg?: string;
  buttonWidth?: string;
  invert?: boolean;
}

export const HeaderSubNavsBox = styled.div<NavBoxProps>`
  min-width: 20rem;
  position: relative;
  background: ${(p) => p.bg || "#fff"};
  padding:3rem 2rem;
  transform: translateX(-30%);
  border-radius: 10px;
  //border:.1rem solid var(--sixthBlue);
`;

export const HeaderSubNavs = styled.div<NavBoxProps>`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  position: relative;
  
  > h1 {
    font-size: 1.4rem;
    opacity:.7;
    margin: 0;
  }
`;

export const HeaderSubNavIcon = styled.div<NavBoxProps>`
margin:0;
padding:0;
transition:all 0.5s;
`;
export const HeaderSubNavButton = styled.div<NavBoxProps>`

min-height:80px
cursor:Pointer;
text-align:left;
transition:all 0.5s;

> div{
    display:flex;
    align-items:center;
    margin:0;
    padding:0;
    gap:14px;
   
}

h2{
    font-size:1.1rem;
    font-weight:600;
    opacity:.8;
    cursor:Pointer;
    color:var(--text-blue);
     margin:.2rem;
     transition:all 0.5s;
}

p{
   
    font-size: .75rem;
    line-height: 1.5;
    opacity:.6;
    font-weight:500;
    cursor:Pointer;
    margin:.4rem .6rem 1.7rem 0;
    transition:all 0.5s;
}
&:hover{
    color:var(--text-blue);
    h2{
        color:var(--sixthBlue);
        opacity:1;

    }
    
    p{
        opacity:1;
    }
    ${HeaderSubNavIcon} {
     
        color:var(--sixthBlue);
      }
}
`;
export const HeaderNavBoxContent = styled.div<NavBoxProps>`
  
  position: absolute;
  padding: 2rem;
  top: 100%;
  transform: rotateX(89);
  right: -50%;
  left: -50%;
  display: none;
`;

export const Triangle = styled.div<NavBoxProps>`
  position: absolute;
  width: 3rem;
  height: 3rem;
  background: ${(p) => p.bg || "#fff"};

  transform: rotateZ(45deg);
  left:30%;
  transform: translateZ(80%);
  top: 1.6rem;
`;

export const HeaderNavBox = styled.div<NavBoxProps>`
  position: relative;
  
  &:hover {
    ${HeaderNavBoxContent} {
      display: flex;
    }
  }
`;

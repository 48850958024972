import { useNavigate } from "react-router-dom";
import {
  NotFoundButton,
  NotFoundContentDiv,
  NotFoundHead,
  NotFoundMainDiv,
  NotFoundText,
} from "../styles/notfoundstyles";
import { PriceCardButton } from "../styles/pricing";
import AppHeader from "./reuseable/header";

const NotFoundComponent = () => {
  const  navigate = useNavigate()
  return (
    <>
      <NotFoundMainDiv>
        <AppHeader
          hideContact={false}
          logo={"/images/sixthBlue.png"}
          top="0.5rem"
          width="10rem"
          mobileWidth="8rem"
        />
        <NotFoundContentDiv>
            <NotFoundHead>
            Whooops, no matches
            </NotFoundHead>
            <NotFoundText>we couldn't find the page you are looking for</NotFoundText>
         
        
          <NotFoundButton>
            <PriceCardButton
            bg="transparent"
            color="var(--text-black)"
            onClick={(e)=>{navigate("/")}}
            >Return</PriceCardButton>
          </NotFoundButton>
        </NotFoundContentDiv>
      </NotFoundMainDiv>
    </>
  );
};

export default NotFoundComponent;

import {
  AboutUsHeadDiv,
  AboutUsHeadHero,
  AboutUsHeadHeroDiv,
  AboutUsLocation,
  AboutUsMainDiv,
  AboutUsStory,
  AboutUsStoryDiv,
  AboutUsWorkWithUsDiv,
  Location,
  LocationCover,
  LocationDiv,
  LocationInfo,
  TeamMember,
  TeamMemberContainer,
  TeamMemberContentDiv,
  TeamMemberDiv,
} from "../../styles/aboutusstyles/aboutUsStyle";
import { Button, SupplText, TextHeader } from "../../styles/reusable";
import { MdWorkOutline } from "react-icons/md";

import Footer from "../reuseable/footer";
import AppHeader from "../reuseable/header";

const AbouUsComponent = () => {
  const openLinkInNewTab = () => {
    window.open("https://calendly.com/ope-trysixth/30min", "_blank");
  };
  return (
    <>
      <AboutUsMainDiv>
        <AboutUsHeadDiv>
          <AppHeader
            hideContact={false}
            logo={"/images/sixthBlue.png"}
            top="0.7rem"
            width="7rem"
            mobileWidth="8rem"
            invert={true}
          />

          <AboutUsHeadHeroDiv>
            <AboutUsHeadHero>
              <TextHeader>
                The Identity
                <br /> Intelligence Experts
              </TextHeader>
              <SupplText>
                Sixth helps brands know who they’re doing business with,
                <br /> so everyone gets the experience they deserve.
              </SupplText>
              <div
                style={{
                  height: "51px",
                  display: "flex",
                  gap: "3%",
                  marginTop: "10%",
                }}
              >
                <Button
                  bg="var(--text-blue)"
                  color="white"
                  hoverBg="transparent"
                  hoverColor=" var(--text-blue)"
                  border="1px solid #03045E"
                  className="expandWidth"
                  onClick={(e) => {
                    openLinkInNewTab();
                  }}
                >
                  REQUEST DEMO
                </Button>
                <Button
                  style={{ marginLeft: "20px" }}
                  bg="transparent"
                  color="var(--text-blue)"
                  hoverBg="var(--text-blue)"
                  hoverColor="white"
                  border="1px solid #03045E"
                  className="expandWidth"
                >
                  WATCH DEMO
                </Button>
              </div>
            </AboutUsHeadHero>
          </AboutUsHeadHeroDiv>
        </AboutUsHeadDiv>
        <AboutUsStoryDiv>
          <AboutUsStory>
            <TextHeader>Our Story</TextHeader>
            <h2>
              Imagine this. The founders of our leading fraud prevention company
              was able to easily hack a popular fintech in Nigeria with over
              300k user in less than 30minutes and no external tools ,crazy
              right
            </h2>
            <SupplText>
              College friends and founders Opeyemi and Precious started Sixth
              for themselves. As very ethusiast Nigerian working in the fintech
              sector, they dicovered how vunlerable the field was from cyber
              attack . And went on to build something to help with this, and
              that my friends is the birth story of Sixth
            </SupplText>
          </AboutUsStory>
        </AboutUsStoryDiv>
        <AboutUsWorkWithUsDiv>
          <MdWorkOutline size={50} color="var(--text-blue)" />

          <TextHeader> Work with us!</TextHeader>
          <SupplText>
            {" "}
            Join us and our commitment to restore trust in digital commerce.
          </SupplText>
          <Button
            bg="var(--text-blue)"
            color="white"
            hoverBg="transparent"
            hoverColor=" var(--text-blue)"
            border="1px solid #03045E"
            className="expandWidth"
            padding="20px"
          >
            SEE OPEN ROLES
          </Button>
        </AboutUsWorkWithUsDiv>
        <AboutUsLocation>
          <TextHeader> Our presence</TextHeader>
          <LocationDiv>
            <Location src="/images/lagosnigeria.jpg">
              <LocationCover>
                <LocationInfo>
                  <TextHeader> Lagos</TextHeader>
                  <SupplText>1 club road ,yaba tech,</SupplText>
                  <SupplText> yaba Lagos ,Lg 101212</SupplText>
                  <h2>Nigeria</h2>
                </LocationInfo>
              </LocationCover>
            </Location>
          </LocationDiv>
          <TextHeader> Our Team</TextHeader>
          <TeamMemberContainer>
            <div>
              <TeamMemberDiv>
                <div>
                  <TeamMember className="first" src="/images/opeyemi.jpg">
                    <TeamMemberContentDiv>
                      {" "}
                      <div className="role">Adewole Opeyemi </div>{" "}
                      <div className="bold">Ceo</div>
                    </TeamMemberContentDiv>{" "}
                  </TeamMember>
                </div>

                <div>
                  <TeamMember className="second" src="/images/bisola.jpg">
                    {" "}
                    <TeamMemberContentDiv>
                      {" "}
                      <div className="role">Falade Bisola </div>
                      <div className="bold"> Software Engineer</div>
                    </TeamMemberContentDiv>
                  </TeamMember>
                </div>
                <div>
                  <TeamMember className="third" src="/images/balogun.jpg">
                    <TeamMemberContentDiv>
                      <div className="role"> Balogun Precious</div>
                      <div className="  bold"> Cto</div>
                    </TeamMemberContentDiv>
                  </TeamMember>
                </div>

                <div>
                  <TeamMember className="fourth" src="/images/king.jpg">
                    {" "}
                    <TeamMemberContentDiv>
                      <div className="role"> Showemimo King</div>{" "}
                      <div className=" bold" > Software Engineer</div>
                    </TeamMemberContentDiv>
                  </TeamMember>
                </div>
              </TeamMemberDiv>
            </div>
          </TeamMemberContainer>
        </AboutUsLocation>

        <Footer />
      </AboutUsMainDiv>
    </>
  );
};

export default AbouUsComponent;

import styled from "styled-components";

export const Centered = styled.div`
  width: 80%;
  margin: 4rem auto;

  > h1 {
    font-size: 36px;
    text-align: center;
  }

  > h2 {
    font-size: 30px;
    font-weight: 500;
    text-align: center;
  }
  >p{
    text-align:center;
  }

  @media (max-width: 728px) {
    width: 90%;
  }
`;

export const PriceMainDiv = styled.div`
  position: relative;
  > div {
    border-radius: 10px;
    border-image: linear-gradient(180deg, #c352d3 0%, #a767db 100%) 1;
  }
`;
export const PriceSection = styled.div`
  text-align: center;
`;

export const PriceItems = styled.div`
  display: flex;
  align-items: top;
  justify-content: space-evenly;
  gap: 90px;
  @media (max-width: 728px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
export const PricingCard = styled.div`
  flex-grow: 1;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 25%);
  box-shadow: 2px 4px 4px 2px #00597840;
  border: 1px solid #03045E;
  box-shadow: 5px 6px 6px 5px #00597833;

  padding: 50px 10px 20px 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 13px;
  width: 47%;
  max-width: 450px;
  min-width: 300px;
  position: relative;

  ::before {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    z-index: -1;
    //background: linear-gradient(180deg, #c352d3 0%, #a767db 100%);
    border-radius: 10px;
   // border-image: linear-gradient(180deg, #c352d3 0%, #a767db 100%) 1;
  }

  > div {
  }
  @media (max-width: 728px) {
    width: 95%;
    padding: 10px 2.5% 30px 2.5%;
  }
`;

interface PriceCardButtonProp {
  bg?: string;
  color?: string;
  border?: string;
}

interface PriceCardSectionProp {
  color?: string;
  itemPos?: string;
  justifyDivContent?: String;
}
export const PriceCardSection = styled.div<PriceCardSectionProp>`
  width: 90%;
  gap: 5px;
  padding: 5px 70px;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: ;
  h5 {
    font-size: 32px;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0px;
  }
  span {
    font-size: 16px;
    font-weight: 600;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;
    color: #757575;
  }
  h3 {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
  }
  > div {
    align-items: center;
    justify-content: ${(p) => p.itemPos || "left"};
    gap: 5px;
    padding: 5px 0;
    fontsize: 15px;
    text-align: left;
    color: ${(p) => p.color || "white"};
    display: flex;
  }

  @media (max-width: 728px) {
    padding: 0px;
  }
`;

interface PriceCardIconProp {
  imgWidth?: string;
  imgHeight?: string;
  margin_left?: string;
}
export const PriceCardIcon = styled.img<PriceCardIconProp>`
  height: ${(p) => p.imgHeight || "24px"};
  width: ${(p) => p.imgWidth || "24px "};
`;

export const PriceCardButton = styled.div<PriceCardButtonProp>`
  height: 64px;
  width: 90%;
  border:  ${(P) => P.border || "0.75px solid var(--text-blue)"};;
  text-align: center;
  justify-content: center;
  border-radius: 100px;
  display: flex;
  background: ${(P) => P.bg || "var(--sixth-gradient6)"};
  color: ${(P) => P.color || "var(--sixth-white)"};
  cursor: pointer;
  align-items: center;
  font-size: 16px;
`;

import React from "react";
import "./App.css";
import Home from "./pages/Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";import PricingPage from "./pages/pricing";
import NotFoundPage from "./pages/notfoundpage";
import CustomerPage from "./pages/Customer";
import AboutUsPage from "./pages/aboutus";
import CareerPage from "./pages/careers";
import NewsRoomPage from "./pages/newsroom";
import PrivacyPage from "./pages/privacyPage";
import EventsPage from "./pages/eventsPage";
import ContactUsPage from "./pages/contactus";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/customer" element={<CustomerPage/>} />
          <Route path="/company" element={<AboutUsPage/>} />
          <Route path="/career" element={<CareerPage/>} />
          <Route path="/newsroom" element={<NewsRoomPage/>} />
          <Route path="/privacy-Security" element={<PrivacyPage/>} />
          <Route path="/events" element={<EventsPage/>} />
          <Route path="/contact-us" element={<ContactUsPage/>} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;

import styled from "styled-components";

export const CustomerMainDiv = styled.div``;

export const CustomerHeadDiv = styled.div`
  min-height: 40vh;
  background: var(--sixthBlue);
`;

export const CustomerDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 4%;
`;

export const CustomerHero = styled.div`
  margin: 1rem 2rem;
  display: flex;
  align-items: start;
  justify-content: center;
  flex-grow: 1;

  @media (max-width: 1400px) {
    flex-wrap: wrap;
  }
`;

export const CustomerHeroLeft = styled.div`
  max-width: 650px;
`;

export const CustomerHeroRight = styled.div`
  margin: 0 4rem;
  border-radius: 12px;

  > iframe {
    height: 350px;
    width: 600px;
    border-radius: 12px;
    object-fit: cover;
    cursor: pointer;
    border: none;
  }

  @media (max-width: 1400px) {
    width: 110%;
    margin: 0 ;
    > iframe {
      height: 350px;
      width: 100%;
    }
  }
`;

export const CustomerInfo = styled.div`
  margin: 6rem 0;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  flex-grow: 1;

  @media (max-width: 1400px) {
    flex-wrap: wrap;
  }

  @media (max-width: 450px) {
    flex-wrap: wrap;
    justify-content: center;
    margin: 2rem 0;
  }
`;
export const CustomerInfoCard = styled.div`
  border-radius: 20px;
  display: flex;
  align-items: center;
  background: #03045e;
  width: 400px;
  max-height: 120px;
  padding: 0.8rem;
  min-height: 90px;
  position: relative;
  opacity: 0.9;
  flex-grow: 1;

  @media (max-width: 1400px) {
    width: 60%;
  }

  @media (max-width: 450px) {
    width: 80%;
  }
`;

export const CustomerInfoAbsolute = styled.div`
  width: 50px;
  height: 50px;
  position: absolute;
  background: #1d49ab;
  top: -22px;
  left: 70px;
  border-radius: 50%;
  opacity: 0.8;
  display: flex;
  flex-direction: row;
  justtify-content: center;
  align-items: center;
`;

export const CustomerBody = styled.div``;

export const CustomerBodyHead = styled.div`
  display: flex;
  flex-wrap:wrap;
  align-items: center;
  justify-content: center;
  gap: 50%;
  padding:1rem 2rem;
  box-shadow: 0 0.25rem 1rem rgba(0, 93, 232, 0.1);
`;

export const CustomerBodyHeadSearch = styled.input`
  height: 50px;
  font-size: 1rem;
  width: 400px;
  border-radius: 10px;
  background: var(--sixthLightBlue);
  padding-left: 0.9rem;
`;

export const CustomerBodyContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
  padding: 4rem 10%;
  background: rgba(0, 93, 232, 0.1) l;
`;

interface CutomerNewsProps {
  src?: string;
}
export const CustomerNews = styled.div<CutomerNewsProps>`
  width: 20%;
  min-width: 300px;
  box-sizing: border-box;
  height: 400px;
  background: transparent;
  border-radius: 10px;
  overflow: hidden;
  position: relative;

  background-image: url("${(p) => p.src || "0.7rem 1.5rem"}");
  background-size: cover;
  background-position: center;
  transition:all .7s;
  &:hover{
    transform:scale(1.1)
  }
`;

export const CustomerCover = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  cursor: pointer;
  flex-direction: column;
  justify-content: end;
  background: var(--sixth-gradient7);
  padding-left: 1rem;
  transition: all 1s;
  z-index: 2;
  >h2{
    color:03045E;
  }
`;

export const CustomerNewsType = styled.div`
  width: fit-content;
  border-radius: 10px;
  background-color: rgb(0, 93, 232, 0.7);

  padding: 5px 5px;

  box-sizing: border-box;

  > p {
    color: white;
    font-size: 20px;
    font-weight: 600;
    margin: 0;
    padding: 0;
  }
`;

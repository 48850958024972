import { Frown } from "react-feather";
import styled from "styled-components";

interface PropsArgs {
  bg?: string;
  color?: string;
  border?: string;
  padding?: string;
  hoverColor?: string;
  hoverBg?: string;
  buttonWidth?: string;
  invert?:boolean
}

export const Button = styled.button<PropsArgs>`
  background: ${(p) => p.bg || "transparent"};
  color: ${(p) => p.color || "var(--text-black)"};
  border: ${(p) => p.border || "none"};
  padding: ${(p) => p.padding || "0.7rem 1.5rem"};
  min-width: ${(p) => p.buttonWidth || "160px"};
  min-height: 40px;
  font-size: 14px;
  border-radius: 50px;
  font-weight: 600;
  transition: all 0.8s;
  &:hover {
    color: ${(p) => p.hoverColor || "var(--sixthPurple)"};
    background: ${(p) => p.hoverBg || "var(--sixthLightPurple)"};

    &.expandWidth {
      transition: all 0.4s;
      min-width: 230px;
      
    }
  }

  @media (max-width: 400px) {
    &:hover {
      &.expandWidth {
        min-width: ${(p) => p.buttonWidth || "160px"};
        background: ${(p) => p.bg || "var(--sixthLightPurple)"};
        ;
      }
    }
  }
`;

export const NavButton = styled.div<PropsArgs>`
background: ${(p) => p.bg || "transparent"};
  color: ${(p) => p.color || "var(--text-blue)"};
  border: ${(p) => p.border || "none"};
  padding: ${(p) => p.padding || "0.8rem 1.0rem 0.2rem 1.0rem"};
  position: relative;
  font-size:1.0rem;
  align-items:center;
  text-outline:black;
  margin: 0 2px;
  overflow: hidden;
  font-weight: 800;
  &:hover {
    color:  ${(p) => p.hoverColor || "var(--text-light-blue)"};
        
    ::before {
           
      transition: 0.1s transform linear, 0.1s height linear 0.1s;
      transform: translateX(0);
      height: 100%;
      border-color:   ${(p) => p.hoverColor || "var(--sixthBlue)"};

          
    }
    ::after {
      transition: 0.1s transform linear 0.2s, 0.1s height linear 0.3s;
      transform: translateX(0);
      height: 100%;
      border-color:   ${(p) => p.hoverColor || "var(--sixthBlue)"};
          
    }
  }
  ::before {
    .box-sizing;         
    transform: translateX(100%);
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 90%;
    height: 0px;
    border-bottom: 2px solid transparent;
    border-left: 2px solid transparent;
    cursor:Pointer;
  }
     
    ::after {
    .box-sizing;
    transform: translateX(-100%);
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 90%;
    height: 0px;
    border-top: 2px solid transparent;
    border-right: 2px solid transparent;
    cursor:Pointer;
  }
`;

export const LeftHero = styled.div`
  display: flex;
  align-items: center;
  width: 45%;

  > div {
    width: 100%;
    padding: ;
    line-height: 0px;

    @media (max-width: 450px) {
      padding: 5rem 0% 2rem 0%;
    }
  }

  @media (max-width: 1000px) {
    margin: 0 auto;
    width: 70%;
  }

  @media (max-width: 450px) {
    width: 100%;
  }
`;

interface HeroProp {
  flexStyle?: string;
}
export const RightHero = styled.section<HeroProp>`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem 0 0 0;
  position: relative;
  justify-content: ${(p) => p.flexStyle || " flex-start"};

  @media (max-width: 450px) {
    width: 100%;
    margin: 5rem 0 0 0;

    > img {
      width: 100%;
    }
  }

  @media (max-width: 1000px) {
    margin: 7rem 0 0 0;
  }
`;

//text
export const TextHeader = styled.h1`
  font-weight: 800;
  font-size: 40px;
  line-height: 50px;

  margin-top: 3rem;

  @media (max-width: 1000px) {
    font-size: 25px;
  }

  @media (max-width: 600px) {
    font-size: 40px;
    line-height: 55px;
    margin-top: 0rem;
    text-align: center;
  }
`;

export const SupplText = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;

  @media (max-width: 450px) {
    font-size: 14px;
    line-height: 30px;
  }
`;

export const TextField = styled.div`
  padding: 0.7rem;
  background: var(--main-bg);
  color: #fff;
  box-shadow: 0px 8px 40px rgba(9, 44, 76, 0.16);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  > input {
    width: 16rem;
    font-size: 14px;
  }

  @media (max-width: 450px) {
    width: 94%;
    padding: 0.7rem 3%;
    margin: 2rem 0 0 0;

    > input {
      width: 50%;
      font-size: 16px;
    }
  }
`;

interface CardSectionProps {
  bg?: string;
  imgSize?: string;
  margin?: string;
}

export const CardSection = styled.div<CardSectionProps>`
  padding: 1rem 0 3rem 0;
  margin: ${(p) => p.margin || "5rem 0"};
  background: ${(p) => p.bg || "var(--main-bg)"};
  justify-content: center;
  align-item: center;
  display: flex;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  img {
    width: ${(p) => p.imgSize || "40%"};
    object-fit: cover;
  }

  @media (max-width: 728px) {
    margin: 0;
    img {
      width: 100%;
    }
  }
`;

interface FlexDataProp {
  bg?: string;
  sectionWidth?: string;
  radius?: string;
  width?: string;
  height?: string;
  border?: string;
  shadow?: string;
  align?: string;
  focus?: string;
}

export const CarouselFlexedData = styled.div<FlexDataProp>`
  display: flex;
  justify-content: center;
  margin-left: 0rem;
  margin-right: 5%;
  margin-top: 0rem;
  background: ${(p) => p.bg || "transparent"};
  border-radius: ${(p) => p.radius || "0px"};
  width: ${(p) => p.width || "auto"};
  min-height: ${(p) => p.height || "auto"};
  min-width: 360px;
  max-width: 1700px;
  box-shadow: ${(p) => p.shadow || "none"};
  border: ${(p) => p.border || "none"};
  overflow: hidden;
  align-items: ${(p) => p.align || "start"};
  scroll-snap-align: center;

  &.animationd {
    transition: transform 0.3s ease-in-out;
    transform: scale(1.1);
  }

  > section {
    align-items: center;
    padding: 0px;
    margin: 0px;
    width: 90%;
    display: flex;
    flex-direction: column;
    text-align: left;
    > h1 {
      font-size: 1rem;
      font-weight: 800;
      line-height: 28px;
      color: #03045e;
      margin-top: 2.5rem;
    }

    h2,
    h3 {
      font-style: normal;
      font-weight: 800;
      font-size: 45px;
      line-height: 55px;
    }

    h3 {
      font-size: 15px;
      line-height: 20px;
    }

    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: #03045e;
    }
  }
  img {
    border-radius: 75px;
    width: 150px;
    margin-top: 70px;
  }
  > div {
    flex: 1;
    margin: 0 1%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 900px) {
    flex-direction: column;
    gap: 50px;
    margin: 0;
    width: 86%;
    text-align: center;

    > section {
      width: 90%;
      padding: 0 5%;
      text-align: center;

      > h2 {
        line-height: 45px;
      }
    }
  }

  @media (max-width: 500px) {
    max-width: 30%;
    box-sizing: border-box;
    gap: 3px;
    flex-direction: column-reverse;

    &.animationd {
      transition: transform 0.3s ease-in-out;
      transform: scale(1);
    }
    > section {
      > h1 {
        font-size: 1rem;
        margin-top: 0.3rem;
      }
    }

    img {
      margin-top: 35px;
      width: 90px;
    }
    > div {
      padding: 0;
    }
  }
`;

export const Carousel = styled.div`
  display: flex;
  padding-left: 10%;
  flex-direction: row;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  scrollbar-width: none;
  gap: 40px;
  margin-bottom: 15%;
  min-height: 74vh;
  align-items: center;
  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Opera */
  }

  @media (max-width: 450px) {
    width: 100%;
    padding-left: 0%;
    flex-direction: column;
    box-sizing: border-box;
    align-items: center;
  }
`;
export const FlexedData = styled.div<FlexDataProp>`
  display: flex;
  justify-content: center;
  margin-left: 0rem;
  margin-right: 4rem;
  margin-top: 9rem;
  background: ${(p) => p.bg || "transparent"};
  border-radius: ${(p) => p.radius || "0px"};
  width: ${(p) => p.width || "auto"};
  min-height: ${(p) => p.height || "auto"};
  min-width: 360px;
  max-width: 1700px;
  box-shadow: ${(p) => p.shadow || "none"};
  border: ${(p) => p.border || "none"};
  overflow: hidden;
  align-items: ${(p) => p.align || "start"};

  > section {
    align-items: left;
    padding: 0px;
    margin: 0px;
    width: ${(p) => p.sectionWidth || "50%"};
    display: flex;
    flex-direction: column;
    text-align: left;

    h2,
    h3 {
      font-style: normal;
      font-weight: 800;
      font-size: 45px;
      line-height: 55px;
    }

    h3 {
      font-size: 15px;
      line-height: 20px;
    }

    p {
      font-weight: 400;
      font-size: 20px;
      line-height: 35px;
      letter-spacing: 0.15px;
    }
  }

  fieldset {
    border: none;
    width: 30%;

    h3 {
      text-align: left;
      font-size: 35px;
    }

    &.img {
      width: 100%;
      height: 60vh;
    }

    @media (max-width: 728px) {
      width: 92%;

      > img {
        height: auto;
      }
    }
  }

  > div {
    flex: 1;
    margin: 0 1%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 900px) {
    flex-direction: column;
    gap: 50px;
    margin: 0;
    width: 86%;
    text-align: center;

    > section {
      width: 90%;
      padding: 0 5%;
      text-align: center;

      > h2 {
        line-height: 45px;
      }
    }
  }
`;

interface VsHeroMainProp {
  bg?: string;
  imageWidth?: string;
  imageHeight?: string;
  imagePadding?: string;
  alignItems?: string;

  height?: string;
  width?: string;
  marginTop?: string;
  marginBottom?: string;
  padding?: string;
  border?: string;
}

export const VsHero = styled.div`
  background: linear-gradient(
    148.74deg,
    #d55bc8 33.92%,
    #9139f0 87.62%,
    rgba(145, 57, 240, 100) 87.62%
  );
`;
export const VsHeroMain = styled.section<VsHeroMainProp>`
  text-align: left;
  justify-content: start;
  align-items: start;
  border: ${(p) => p.border || ""};
  margin-top: ${(p) => p.marginTop || "auto"};
  margin-bottom: ${(p) => p.marginTop || "10rem"};
  position: relative;
  display: flex;
  align-items: ${(p) => p.alignItems || "center"};
  justify-content: space-between;
  gap: 0px;
  width: 84%;
  background: ${(p) => p.bg || "transparent"};
  padding: ${(p) => p.padding || "2rem 7.4%"};
  min-height: ${(p) => p.height || "auto"};
  min-width: ${(p) => p.width || "85%"};

  border-image: linear-gradient(180deg, #ea30f5 0%, #8c30f5 100%) 1;
  > div {
    margin: 0px;
  }

  img {
    height: ${(p) => p.imageHeight || "450px"};
    width: ${(p) => p.imageWidth || "40rem"};
    padding-top: ${(p) => p.imagePadding || "auto"};
  }

  @media (max-width: 600px) {
    margin: 0rem auto 0 auto;
    padding: 2rem 0%;

    > h2 {
      font-size: 25px;
      line-height: 35px;
      width: 90%;
    }

    img {
      height: auto;
      width: 100%;
    }

    > p {
      padding: 0 5%;
    }
  }

  @media (max-width: 1440px) {
    gap: 30px;
  }

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    gap: 0;
  }

  @media (max-width: 450px) {
    flex-direction: column;
    width: auto;
    text-align: center;
    gap: 0;
    padding: auto;
    margin: 1rem;
    > img {
      width: 100%;
    }
  }
`;

interface SmallGradientHeroProps {
  src?: string;
  bg?: string;
  color?: string;
  height?: string,
  width?: string,
  min_width?: string,
}
export const SmallGradientHero = styled.div<SmallGradientHeroProps>`
  width:  ${(p) => p.width || "15%"};;
  min-width:${(p) => p.min_width || "300px"};;
  box-sizing: border-box;
  min-height: ${(p) => p.height || "400px"};
  background: var(--sixth-gradient);
  border-radius: 10px;
  position: relative;
  display: flex;
  justify-content: end;
  flex-direction: column;
  box-sizing: border-box;
  text-align: left;

  background: ${(p) => p.bg || "var(--text-blue)"};
  background-image: url("${(p) => p.src || ""}");
  
  color: ${(p) => p.color || "white"};
  background-size: cover;
  background-position: center;
  
  overflow:hidden;
  > div {
    display: flex;
    justify-content: start;
    background: var(--sixth-gradient7);
    padding: 20px;
    padding-top:30%;
    height:100%;
    flex-direction: column;
    box-sizing: border-box;
  }
  p {
    font-size: 1rem;
    line-height: 1.375;
    font-weight:700;
  }
  h1 {
    color: ${(p) => p.color || "white"};
    font-size: 1.75rem;
    line-height: 1.125;
  }
  @media (max-width: 460px) {
    min-width: 250px;
    height: 300px;
     > div {
   
    padding: 10px;
    justify-content: end;

   
   
  }
  }
`;

import styled from "styled-components";

 export const NotFoundMainDiv = styled.div`
 `
 
 export const NotFoundHead = styled.h1`
 font-size:3.4rem;
 margin-bottom:0px;
 `
 
 export const NotFoundText = styled.p`
 color: rgb(140, 140, 140);

 
 `


 export const NotFoundContentDiv = styled.div`
 align-items:center;
 display:flex;
 flex-direction:column;
 justify-content:center;
 height:90vh;
 text-align:center;
 
 `

 export const NotFoundButton = styled.div`

 min-width: 100px;
 max-width: 350px;
 height: 50px;
 width: 40%;
 font-size

 `
import React, { useEffect } from "react";
import HomeHero from "../components/Homecomps/homehero";
import Services from "../components/Homecomps/service";
import DemoSection from "../components/Homecomps/demoSection";
import Tools from "../components/Homecomps/tools";
import Footer from "../components/reuseable/footer";
import Testimonial from "../components/Homecomps/testimonial";
import ChatWithUsCompnents from "../components/catComponents/chatWithUsCmpnents";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="main-widget">
        <main>
          <HomeHero />
          <Services />
          <Testimonial />
          <ChatWithUsCompnents/>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default Home;

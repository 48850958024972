import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import { ImgHero } from "../../styles/homeComps/homeHeroStyles";
import { ServiceMain } from "../../styles/homeComps/serviceStyles";
import { FlexedData, TextHeader } from "../../styles/reusable";
import Reveal from "../reuseable/Reveal";

const Services = () => {
  return (
    <>
    

      <ServiceMain>
        <div>
          <FlexedData width="100%" height="80vh" align="normal">
            <Reveal delay=".1s">
              <div style={{height:"40vw"}}>
                <DotLottieReact
                      id="animation"
                      src="/images/secure_transaction.lottie"
                      loop
                      autoplay
                      style={{height:"auto", position:"absolute", left:0}}
                    />
              </div>
              
              </Reveal>
            
            <section>
              <Reveal >
                <TextHeader style={{fontSize:"36px", fontWeight:"800", lineHeight: "50px", color:"#03045E"}}>
                Ensure Comprehensive Transaction<br/> Security
                </TextHeader>
              </Reveal>
              <Reveal direction="right">
                <div style={{padding:"0px", gap:'0px', margin:"0px"}}>
                  <div style={{display:"flex", flexDirection:"row"}}>
                    <img src="/images/check-mark.svg"></img>
                    <p style={{marginLeft:"4px", fontSize:"16px", fontWeight:"500", color:"#03045E"}}>
                    Guarantee unique, error-free transactions.
                    </p>
                  </div>
                  <div style={{display:"flex", justifyContent:"row", marginBottom:"100px", padding:"0px"}}>
                    <img src="/images/check-mark.svg"></img>
                    <p style={{marginLeft:"4px", fontSize:"16px", fontWeight:"500", color:"#03045E"}}>
                    Safeguard against financial discrepancies and fraud.
                    </p>
                  </div>
                </div>

                
                
              </Reveal>
            </section>
          </FlexedData>
          <FlexedData width="100%" height="80vh" align="normal">
            <Reveal delay=".1s">
              <div style={{height:"40vw"}}>
                <DotLottieReact
                      id="animation"
                      src="/images/tools.lottie"
                      loop
                      autoplay
                      style={{height:"auto", position:"absolute", left:0}}
                    />
              </div>
              
              </Reveal>
            
            <section>
              <Reveal >
                <TextHeader style={{fontSize:"36px", fontWeight:"800", lineHeight: "50px", color:"#03045E"}}>
                Integrate Seamlessly with Minimal Technical Effort
                </TextHeader>
              </Reveal>
              <Reveal direction="right">
                <div style={{padding:"0px", gap:'0px', margin:"0px"}}>
                  <div style={{display:"flex", flexDirection:"row"}}>
                    <img src="/images/check-mark.svg"></img>
                    <p style={{marginLeft:"4px", fontSize:"16px", fontWeight:"500", color:"#03045E"}}>
                    Simple SDK integration without extensive technical work.
                    </p>
                  </div>
                  <div style={{display:"flex", justifyContent:"row", marginBottom:"100px", padding:"0px"}}>
                    <img src="/images/check-mark.svg"></img>
                    <p style={{marginLeft:"4px", fontSize:"16px", fontWeight:"500", color:"#03045E"}}>
                    Compatible with a wide range of Fintech platforms and systems.
                    </p>
                  </div>
                </div>

                
                
              </Reveal>
            </section>
          </FlexedData>
          <FlexedData width="100%" height="80vh" align="normal">
            <Reveal delay=".1s">
              <div style={{height:"40vw"}}>
                <DotLottieReact
                      id="animation"
                      src="/images/monitor.lottie"
                      loop
                      autoplay
                      style={{height:"auto", position:"absolute", left:0}}
                    />
              </div>
              
              </Reveal>
            
            <section>
              <Reveal >
                <TextHeader style={{fontSize:"36px", fontWeight:"800", lineHeight: "50px", color:"#03045E"}}>
                Monitor and Manage Transactions<br/> in Real-Time
                </TextHeader>
              </Reveal>
              <Reveal direction="right">
                <div style={{padding:"0px", gap:'0px', margin:"0px"}}>
                  <div style={{display:"flex", flexDirection:"row"}}>
                    <img src="/images/check-mark.svg" alt="icon"></img>
                    <p style={{marginLeft:"4px", fontSize:"16px", fontWeight:"500", color:"#03045E"}}>
                    Continuous oversight to detect and prevent duplicates.
                    </p>
                  </div>
                  <div style={{display:"flex", justifyContent:"row", marginBottom:"100px", padding:"0px"}}>
                    <img src="/images/check-mark.svg"></img>
                    <p style={{marginLeft:"4px", fontSize:"16px", fontWeight:"500", color:"#03045E"}}>
                    Immediate alerts for any anomalies detected.
                    </p>
                  </div>
                </div>

                
                
              </Reveal>
            </section>
          </FlexedData>
          <FlexedData width="100%" height="80vh" align="normal">
            <Reveal delay=".1s">
              <div style={{height:"40vw"}}>
                <DotLottieReact
                      id="animation"
                      src="/images/trust.lottie"
                      loop
                      autoplay
                      style={{height:"auto", position:"absolute", left:0}}
                    />
              </div>
              
              </Reveal>
            
            <section>
              <Reveal >
                <TextHeader style={{fontSize:"36px", fontWeight:"800", lineHeight: "50px", color:"#03045E"}}>
                Build and Maintain Enhanced Customer Trust
                </TextHeader>
              </Reveal>
              <Reveal direction="right">
                <div style={{padding:"0px", gap:'0px', margin:"0px"}}>
                  <div style={{display:"flex", flexDirection:"row"}}>
                    <img src="/images/check-mark.svg"></img>
                    <p style={{marginLeft:"4px", fontSize:"16px", fontWeight:"500", color:"#03045E"}}>
                    Eliminate transaction errors that can undermine customer confidence.
                    </p>
                  </div>
                  <div style={{display:"flex", justifyContent:"row", marginBottom:"100px", padding:"0px", color:"#03045E"}}>
                    <img src="/images/check-mark.svg"></img>
                    <p style={{marginLeft:"4px", paddingTop:"0px", fontSize:"16px", fontWeight:"500", color:"#03045E"}}>
                    Deliver a reliable and secure financial experience.
                    </p>
                  </div>
                </div>

                
                
              </Reveal>
            </section>
          </FlexedData>
          <FlexedData width="100%" height="80vh" align="normal">
            <Reveal delay=".1s">
              <div style={{height:"40vw"}}>
                <DotLottieReact
                      id="animation"
                      src="/images/tailor.lottie"
                      loop
                      autoplay
                      style={{height:"auto", position:"absolute", left:0}}
                    />
              </div>
              
              </Reveal>
            
            <section>
              <Reveal >
                <TextHeader style={{fontSize:"36px", fontWeight:"800", lineHeight: "50px", color:"#03045E"}}>
                Tailor Custom Solutions to Meet <br/>Your Unique Needs
                </TextHeader>
              </Reveal>
              <Reveal direction="right">
                <div style={{padding:"0px", gap:'0px', margin:"0px"}}>
                  <div style={{display:"flex", flexDirection:"row"}}>
                    <img src="/images/check-mark.svg"></img>
                    <p style={{marginLeft:"4px", fontSize:"16px", fontWeight:"500", color:"#03045E"}}>
                    Customized offerings designed for your specific fintech requirements.
                    </p>
                  </div>
                  <div style={{display:"flex", justifyContent:"row", marginBottom:"100px", padding:"0px", color:"#03045E"}}>
                    <img src="/images/check-mark.svg"></img>
                    <p style={{marginLeft:"4px", paddingTop:"0px", fontSize:"16px", fontWeight:"500", color:"#03045E"}}>
                    Expert support to optimize your transaction security processes.
                    </p>
                  </div>
                </div>

                
                
              </Reveal>
            </section>
          </FlexedData>
        </div>
      </ServiceMain>
    </>
  );
};

export default Services;

import React, { useEffect } from "react";
import {
  PriceCardButton,
  PriceCardIcon,
  PriceCardSection,
  PriceItems,
  PriceMainDiv,
  PriceSection,
  PricingCard,
  Centered
} from "../styles/pricing";
import AppHeader from "./reuseable/header";

const Pricing = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const openRegisterTab = () => {
    window.open("https://app.trysixth.com", "_blank");
  };

  return (
    <>
      <div className="main-widget">
        <PriceMainDiv>
          <div>
            
        
          <AppHeader
            hideContact={false}
            logo={"/images/sixthBlue.png"}
            top="0.5rem"
            width="10rem"
            mobileWidth="8rem"
          />
          <Centered>
            <h1>Plans and Pricing</h1>
            <p>
            Ready to get started choose a plan that works 
            </p>
            {PricingData.map((item, index) => (
              
                <PriceSection>
                <PriceCardSection itemPos="center">
                  <div>
                    { //<PriceCardIcon
                      //imgWidth="70px"
                      //imgHeight="60px"
                      //alt="img"
                      //src={item.HeaderImage}
                      ///>
                      //<TextHeader>{item.Header}</TextHeader>

                    }
                   
                  </div>
                </PriceCardSection>
                <PriceItems>
                  {item.card.map((card, index) => (
                    <PricingCard>
                      <PriceCardSection color={card.PlanColor}>
                        <div>
                          {card.PlanImage ? (
                            <PriceCardIcon
                              imgHeight="20px"
                              imgWidth="20px"
                              alt="img"
                              src={card.PlanImage}
                            />
                          ) : (
                            <></>
                          )}

                          {card.Plan}
                        </div>
                      </PriceCardSection>

                      <PriceCardSection>
                        <h5>
                          {card.Price}
                          <span>{card.Frequency}</span>
                        </h5>
                        <h3>{card.brief}</h3>
                      </PriceCardSection>
                      <PriceCardSection color="var(--text-black)">
                        {card.benefits.map((benefit, index) => (
                          <div>
                            <PriceCardIcon
                              alt="img"
                              src="/images/ph_seal-check.png"
                            />
                            {benefit}
                          </div>
                        ))}
                      </PriceCardSection>

                      <PriceCardButton border="none" onClick={(e) => openRegisterTab()}>Pay now </PriceCardButton>

                      

                      <br />
                      <br />
                    </PricingCard>
                  ))}
                </PriceItems>
              </PriceSection>  
              
              
            ))}
          </Centered>
          <div
            style={{
              width: "100%",
              justifyContent: "center",
              display: "flex",
            }}
          >
        
          </div>
          </div>
            

         
        </PriceMainDiv>
        
      </div>
    </>
  );
};

export default Pricing;

const PricingData = [
  
  {
    Header: "Rate Limiter",
    HeaderImage: "/images/material-symbols_developer-mode-outline.svg",
    card: [
      {
        PlanImage: "",
        Plan: "Standard Plan",
        PlanColor:"#00171F",
        Price: "$99.99/ ",
        Frequency: "monthly",
        brief: "Protect your resources dynamically.",
        benefits: [
          "Seamlessly configure each endpoint rate limit options", 
          "Detailed Slack logs of rate limit attacks.", 
          "Tailor rate limit options to suit your needs"],
      }
    ],
  }
];

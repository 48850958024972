import React, { useState } from "react";
import { MdWorkOutline } from "react-icons/md";
import { ImNewspaper } from "react-icons/im";
import { FaCalculator } from "react-icons/fa";
import { MdOutlineArrowDropDown } from "react-icons/md";

import { GrDocumentCloud } from "react-icons/gr";
import { BsCalendarEventFill } from "react-icons/bs";
import { RiGitRepositoryPrivateLine } from "react-icons/ri";

import { MdContactSupport } from "react-icons/md";

import { IoBusiness } from "react-icons/io5";

import {
  HeaderBtn,
  HeaderNavBox,
  HeaderNavBoxContent,
  HeaderSubNavButton,
  HeaderSubNavIcon,
  HeaderSubNavs,
  HeaderSubNavsBox,
  LogoImage,
  MainHead,
  MainWrap,
  MobileMenuController,
  MobileModal,
  MobileNav,
  MobileNavName,
  MobileNavSubNav,
  Triangle,
} from "../../styles/headerStyle";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { Button, NavButton, TextHeader } from "../../styles/reusable";
import * as Icon from "react-feather";
import { url } from "inspector";
import { MdOutlineArrowDropUp } from "react-icons/md";

import { GrAnnounce } from "react-icons/gr";

interface Props {
  logo: string;
  hideContact: boolean;
  width?: string;
  top?: string;
  mobileWidth?: string;
  invert?: boolean;
}

const AppHeader = ({
  logo,
  hideContact,
  width,
  top,
  mobileWidth,
  invert = false,
}: Props) => {
  const navigate = useNavigate();
  const [showMobile, setShowMobile] = useState(false);
  const [activeIndex, SetActiveIndex] = useState(-1);
  return (
    <>
      <MainWrap>
        <MainHead>
          <Link to="/">
            <LogoImage
              width={width}
              mobileWidth={mobileWidth}
              top={top}
              src={logo}
              alt="sixth"
            />
          </Link>
          {hideContact ? null : (
            <HeaderBtn
            // to='/contact
            >
              {data.map((item, index) => (
                <HeaderNavBox>
                  <NavButton
                    hoverColor={invert ? "white" : ""}
                    onClick={(e) => {
                      if (item.action === "" || item.action === null) {
                        if (item.subLinks.length <= 0) navigate(item.url);
                      } else {
                        window.location.href = item.action;
                      }
                    }}
                  >
                    <Link to="/">{item.name}</Link>
                  </NavButton>
                  {item.subLinks.length > 0 ? (
                    <HeaderNavBoxContent>
                      <Triangle bg=""></Triangle>
                      <HeaderSubNavsBox>
                        {item.subLinks.map((subLink, index) => (
                          <HeaderSubNavs>
                            {subLink.subLinks ? (
                              <>
                                <TextHeader>{subLink.header}</TextHeader>
                                {subLink.subLinks.map((links, index) => (
                                  <HeaderSubNavButton
                                    onClick={(e) => {
                                      if (
                                        links.action === "" ||
                                        links.action === null
                                      ) {
                                        navigate(links.url);
                                      } else {
                                        window.location.href = links.action;
                                      }
                                    }}
                                  >
                                    <div>
                                      {" "}
                                      <HeaderSubNavIcon>
                                        {" "}
                                        {links.icon}{" "}
                                      </HeaderSubNavIcon>
                                      <h2> {links.name} </h2>
                                    </div>

                                    <p> {links.description} </p>
                                  </HeaderSubNavButton>
                                ))}
                              </>
                            ) : (
                              <HeaderSubNavButton
                                onClick={(e) => {
                                  if (
                                    subLink.action === "" ||
                                    subLink.action === null
                                  ) {
                                    navigate(subLink.url);
                                  } else {
                                    window.location.href = subLink.action;
                                  }
                                }}
                              >
                                <div>
                                  {" "}
                                  <HeaderSubNavIcon>
                                    {" "}
                                    {subLink.icon}{" "}
                                  </HeaderSubNavIcon>
                                  <h2> {subLink.name} </h2>
                                </div>

                                <p> {subLink.description} </p>
                              </HeaderSubNavButton>
                            )}

                            {}
                          </HeaderSubNavs>
                        ))}
                      </HeaderSubNavsBox>
                    </HeaderNavBoxContent>
                  ) : (
                    <></>
                  )}
                </HeaderNavBox>
              ))}
              <Button
                bg="var(--sixthLightBlue)"
                color="#03045E"
                hoverBg="var(--sixthBlue)"
                hoverColor="white"
                buttonWidth="100px"
                style={{ marginTop: "0.4rem" }}
                onClick={(e) => {
                  window.open(
                    "https://calendly.com/ope-trysixth/30min",
                    "_blank"
                  );
                }}
              >
                Contact us
              </Button>
            </HeaderBtn>
          )}
          <MobileMenuController
            mobileColor="var(--text-black)"
            onClick={() => setShowMobile(true)}
          >
            <Icon.Menu size={38} strokeWidth={1} />
          </MobileMenuController>
        </MainHead>
        {showMobile ? (
          <MobileModal>
            <i>
              <Icon.X onClick={() => setShowMobile(false)} />
            </i>
            <Link to="/">
              <LogoImage
                width={width}
                mobileWidth={mobileWidth}
                top={top}
                src={logo}
                alt="sixth"
              />
            </Link>
            <div>  
              {data.map((item, index) => (
                <MobileNav>
                  <MobileNavName
                    onClick={(e) => {
                      if (item.subLinks.length > 0 ) {
                        if(activeIndex !=index ) 
                            SetActiveIndex(index)
                          else
                          SetActiveIndex(-1)
                      
                        
                      } else {
                        if (item.url != "") navigate(item.url);
                        else if(item.action !=null) {
                          window.location.href = item.action;
                        }
                      }
                    }}
                  >
                    <p className="navigation-text">{item.name}  <>{item.open}</></p>
                    {item.subLinks.length > 0 ? (
                      <>
                        {activeIndex !=index? (
                          <MdOutlineArrowDropUp size={30} />):(
                            <MdOutlineArrowDropDown size={30} />
                            )
                        }
                      
                     
                      </>
                    
                    ) : (
                      <></>
                    )}
                  </MobileNavName>
                  <hr />
                 
                  {activeIndex == index ? (
                    <MobileNavSubNav>
                      {item.subLinks.map((subnavs, items) => (
                        <div>
                          {subnavs.subLinks ? (
                            <>
                              <TextHeader>{subnavs.header}</TextHeader>
                              {subnavs.subLinks.map((links, index) => (
                                <HeaderSubNavButton
                                  onClick={(e) => {
                                    if (
                                      links.action === "" ||
                                      links.action === null
                                    ) {
                                      navigate(links.url);
                                    } else {
                                      window.location.href = links.action;
                                    }
                                  }}
                                >
                                  <div>
                                    {" "}
                                    <HeaderSubNavIcon>
                                      {" "}
                                      {links.icon}{" "}
                                    </HeaderSubNavIcon>
                                    <h2> {links.name} </h2>
                                  </div>

                                  <p> {links.description} </p>
                                </HeaderSubNavButton>
                              ))}
                            </>
                          ) : (
                            <HeaderSubNavButton
                              onClick={(e) => {
                                if (
                                  subnavs.action === "" ||
                                  subnavs.action === null
                                ) {
                                  navigate(subnavs.url);
                                } else {
                                  window.location.href = subnavs.action;
                                }
                              }}
                            >
                              <div>
                                {" "}
                                <HeaderSubNavIcon>
                                  {" "}
                                  {subnavs.icon}{" "}
                                </HeaderSubNavIcon>
                                <h2> {subnavs.name} </h2>
                              </div>

                              <p> {subnavs.description} </p>
                            </HeaderSubNavButton>
                          )}
                        </div>
                      ))}
                    </MobileNavSubNav>
                  ) : (
                    <></>
                  )}
                </MobileNav>
              ))}
              <div>
                <Button
                  bg="var(--sixthBlue)"
                  color="white"
                  onClick={(e) => navigate("/About us")}
                >
                  Contact us
                </Button>
              </div>
            </div>
          </MobileModal>
        ) : null}
      </MainWrap>
    </>
  );
};

const data = [
  {
    name: "Home",
    url: "/",
    action: null,
    subLinks: [],
    open: false,
  },
  {
    name: "Customers",
    url: "/Customer",
    action: null,
    open: false,
    subLinks: [],
  },
  {
    name: "About us",
    url: "/company",
    action: null,
    open: false,

    subLinks: [
      {
        header: "",
        name: "About us",
        url: "/company",
        action: null,
        subLinks: null,
        description: " Want to Know who we are learn More ... ",
        icon: <IoBusiness size={28} />,
      },
      {
        header: "",
        name: "Careers",
        url: "/career",
        action: null,
        subLinks: null,
        description: "Interested in working for us come grow your career",
        icon: <MdWorkOutline size={28} />,
      },
      {
        header: "",
        name: "Announcements",
        url: "/newsroom",
        action: null,
        subLinks: null,
        description: " see our latest announcements and updates  ",
        icon: <GrAnnounce size={28} />,
      },

      {
        header: "",
        name: "privacy & Security",
        url: "/privacy-Security",
        action: null,
        subLinks: null,
        description: "Learn how we protect your privacy",
        icon: <RiGitRepositoryPrivateLine size={27} />,
      },

      {
        header: "",
        name: "Contact us",
        url: "/contact-us",
        action: null,
        subLinks: null,
        description: "Speak to us",
        icon: <MdContactSupport size={29} />,
      },
    ],
  },
  {
    name: "Resources",
    url: "",
    action: "https://docs.trysixth.com",
    open: false,
    subLinks: [
      {
        header: "Resources",
        name: "Home",
        url: "/",
        action: null,
        icon: <IoBusiness />,
        subLinks: [
          {
            name: "Blogs",
            description: "Get the latest news on frauds prevention and more  ",
            url: "",
            action: "https://blog.trysixth.com/",
            subLinks: null,
            icon: <ImNewspaper size={26} />,
          },
          {
            name: "Documentation",
            description: "Read more about sixth documentation ",
            url: "",
            action: "https://docs.trysixth.com",
            subLinks: null,
            icon: <GrDocumentCloud size={26} />,
          },
          {
            name: "Events",
            description:
              "Get the latest news and register for sixth upcoming events  ",
            url: "/events",
            action: null,
            subLinks: null,
            icon: <BsCalendarEventFill size={26} />,
          },
          //{
          //  name: "ROI calculator",
          //  description: " See how sixth can maximize your roi ",
          //  url: "",
          //  action: "https://blog.trysixth.com/",
          //  subLinks: null,
          //  icon: <FaCalculator size={26} />,
          //},
        ],
      },
    ],
  },
];

export default AppHeader;

import React from "react";
import {
  HeroHeaderWrap,
  HomeHeroDiv,
  ImgHero,
  TrustedSection,
  TrustedSectionImg,
} from "../../styles/homeComps/homeHeroStyles";
import AppHeader from "../reuseable/header";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";

import { Button, LeftHero, SupplText, TextHeader } from "../../styles/reusable";
import Reveal from "../reuseable/Reveal";

const HomeHero = () => {
  const openLinkInNewTab = () => {
    window.open("https://calendly.com/ope-trysixth/30min", "_blank");
  };

  const openRegisterTab = () => {
    window.open("https://calendly.com/ope-trysixth/30min", "_blank");
  };
  return (
    <>
      <HeroHeaderWrap>
        <div>
          <AppHeader
            hideContact={false}
            logo={"/images/sixthBlue.png"}
            top="0.7rem"
            width="7rem"
            mobileWidth="8rem"
          />

          <HomeHeroDiv align={"flex-end"} height="50vh">
            <LeftHero>
              <div>
                <Reveal direction="bottom">
                  <TextHeader style={{ color: "#03045E" }}>
                    Secure Every{" "}
                    <span style={{ color: "var(--text-light-blue)" }}>
                      {" "}
                      Transaction{" "}
                    </span>
                    ,
                    <br />
                    Prevent Duplicates,{" "}
                    <span style={{ color: "var(--text-light-blue)" }}>
                      {" "}
                      Avoid Debit Once, Credit Twice.
                    </span>{" "}
                    <br />
                  </TextHeader>
                </Reveal>

                <Reveal direction="bottom" delay=".3s">
                  <SupplText
                    style={{ paddingBottom: "24px", color: "#03045E" }}
                  >
                    Ensure{" "}
                    <span style={{ color: "var(--text-light-blue)" }}>
                      unique and reliable
                    </span>{" "}
                    to eliminate financial discrepancies <br></br>
                    and also enhance customer trust in your financial operations
                    <br />
                    Ready to get started ?.
                    <span style={{ color: "var(--text-light-blue)" }}>
                      Start with a conversation with us .
                    </span>{" "}
                    <br />
                  </SupplText>
                </Reveal>

                <Reveal width="100%" direction="top">
                  <div style={{ display: "flex", gap: "5%", marginTop: "10%" }}>
                    <Button
                      bg="var(--text-blue)"
                      color="white"
                      hoverBg="white"
                      hoverColor=" var(--text-blue)"
                      border="1px solid #03045E"
                      buttonWidth=""
                      onClick={(e) => openRegisterTab()}
                      className="expandWidth"
                    >
                      Contact us
                    </Button>
                    <Button
                      bg="transparent"
                      color="var(--text-blue)"
                      hoverBg="var(--text-blue)"
                      hoverColor="white"
                      border="1px solid #03045E"
                      onClick={(e) => openLinkInNewTab()}
                      className="expandWidth"

                    >
                      Schedule a demo
                    </Button>
                  </div>
                </Reveal>
              </div>
            </LeftHero>

            <ImgHero width="806px" left="-5vw" bottom="1.2rem">
              <DotLottieReact
                src="https://lottie.host/0ee23f8d-0630-4896-a254-2da5ed0c46d8/GgJlnM5iCw.json"
                loop
                autoplay
                className="imgHero"
              />
            </ImgHero>
          </HomeHeroDiv>
        </div>
      </HeroHeaderWrap>
      <TrustedSection>
        <h1>Trusted by</h1>

        <div>
          {
            //<TrustedSectionImg
            //   src="/images/mono_logo.png"
            //   alt="mono"
            //   imgHeight="30px"
            //   imgWidth="200px"
            //   mobileHeight="35px"
            // />
            // <TrustedSectionImg
            // src="/images/ingressive.png"
            // alt="ingressive"
            // imgWidth="165px"
            // imgHeight="45px"
            // mobileHeight="40px"
            //>
            //<TrustedSectionImg
            //  src="/images/venmob.png"
            //  alt="venmob"
            //  imgWidth="165px"
            //  imgHeight="45px"
            //  mobileHeight="40px"
            ///>
          }

          <TrustedSectionImg
            src="/images/mono_logo.svg"
            alt="mono"
            imgHeight="30px"
            imgWidth="200px"
            mobileHeight="35px"
          />

          <TrustedSectionImg
            src="/images/bridgecard.png"
            alt="bridgecard"
            imgHeight="85px"
            imgWidth="205px"
          />
          <TrustedSectionImg
            src="/images/venco.png"
            alt="venco"
            imgWidth="165px"
            imgHeight="30px"
            mobileHeight="30px"
          />
          <TrustedSectionImg
            src="/images/vendy_logo.png"
            alt="bridgecard"
            imgHeight="65px"
            imgWidth="200px"
            mobileHeight="60px"
          />
        </div>
      </TrustedSection>
    </>
  );
};

export default HomeHero;

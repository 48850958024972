import styled from "styled-components";



export const PrivacyMainDiv = styled.div`
  overflow: hidden;
`;

export const PrivacyHero = styled.div`
  min-height: 40vh;
  background: var(--text-blue);
`;



export const PrivacyHeroContent = styled.div`
  min-height: 40vh;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: start;
  flex-direction: column;
  gap: 2rem;
  button {
    font-size: 1.2rem;
    padding: 20px;
    opacity: 1;
    &:hover {
      opacity: 0.8;
    }
  }
  > h2 {
    color: white;
    font-size: 1.7rem;
    line-height: 1.125;
    width:50%;

  }
  > h1 {
    color: white;
    font-size: 3.625rem;
    line-height: 1.125;
    margin-bottom: 0;
  }

  @media (max-width: 1160px) {
    padding: 4rem 0.3rem;
    > h2 {
      
      width:70%;
  
    }
  }
  @media (max-width: 460px) {
    padding: 4rem 0.3rem 1rem;
    min-height: 10vh;

    > h2 {
      
      width:90%;
  
    }
    overflow: hidden;
    gap: 12px;
    h1 {
      font-size: 2.76rem;
    }
    h2 {
      font-size: 1.2rem;
    }
  }
`;



export const PrivacyDiv = styled.div`
display:flex;
flex-wrap:wrap;
padding:4rem;
justify-content:center;

@media (max-width: 460px) {
  padding:0 2rem;

  
}
  
`;


export const PrivacyMissionDiv = styled.div`
  margin: 4rem 0;
  flex: 1;
  
  min-width:340px;
  max-width:340px;
  
  padding: 0.2rem;
  color: white;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content:space-between;
  flex-direction: column;
  text-align: left;
  gap: 0;
  > h2 {
    color: white;
    font-size: 1.375rem;
    line-height: 1.375;
    text-align: center;

  }
  > p {
    margin: 0;
    font-size: 1rem;
    line-height: 1.375;
    margin: 20px;
  }

  @media (max-width: 1560px) {
    min-width: 300px;
    max-width: 400px;
  }

  @media (max-width: 460px) {
    width: 93%;
    margin: 3rem 0;
  }
`;


export const PrivacyMissionIcon = styled.section`
  background: white;
  color: var(--sixthBlue);
  width: 60px;
  height: 60px;
  display: flex;
  border-radius: 30px;
  align-items: center;
  justify-content: center;
`;


export const ContactUsDiv = styled.div`
  margin: 5rem 0;
  align-items: center;
  text-align: center;
  padding: 6rem 1rem;
  box-sizing: border-box;
 
  > h1 {
    font-size: 3.75rem;
    line-height: 1.125;
    color: var(--text-blue);
  }
  > p {
    margin: 0;
    font-size: 1.25rem;
    line-height: 1.375;
    margin: 20px;
  }
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-grow: 1;
    color: var(--text-blue);
    
    flex: 1;
    flex: 1 1 0;
  }
  
  @media (max-width: 460px) {
    > div {
      flex-direction: column;
    }
  }

  @media (max-width: 460px) {
    padding: 2rem 0.3rem;
    overflow: hidden;
    gap: 12px;
    h1 {
      font-size: 2.76rem;
    }
    h2 {
      font-size: 1.7rem;
    }
    > div {
      flex-direction: column;
    }
  }
`;